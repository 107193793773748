import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CategoryDropDown from '../Shared/DropDown/CategoryDropDown';
import SubCategoryDropDown from '../Shared/DropDown/SubCategoryDropDown';
import BrandDropDown from '../Shared/DropDown/BrandDropDown';
import { useForm } from 'react-hook-form';
import InputField from '../Shared/Input/FormField';
import TextArea from '../Shared/Input/TextArea';
import ToggleSwitch from '../Shared/Input/ToggleSwitch';
import DropZone from './DropZone';
import TextEditor from './TextEditor';
import { DeleteProductImage, ProductFormSubmit } from './ProductFunction';
import { PRODUCT_IMAGE_BASE_URL } from '../../API/apiUrls';
import ColorDropDown from '../Shared/DropDown/ColorDropDown';
import SizeDropDown from '../Shared/DropDown/SizeDropDown';

const AddProduct = () => {
    const navigate = useNavigate();
    const [mode, setMode] = useState("Add");
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [editorHtml, setEditorHtml] = useState('');
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [iscod, setIsCod] = useState(false);
    const [isBestSeller, setIsBestSeller] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isDiscounted, setIsDiscounted] = useState(false);
    const [isNewArrival, setIsNewArrival] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadedImagesId, setUploadedImagesId] = useState([]);


    const handleTagInput = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            if (tagInput.trim() && !tags.includes(tagInput.trim())) {
                setTags([...tags, tagInput.trim()]);
                setTagInput('');
            }
        }
    };

    const removeTag = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };


    useEffect(() => {
        const productDataString = sessionStorage.getItem("product_data");
        const productData = productDataString ? JSON.parse(productDataString) : null;

        if (productData) {
            setMode("Edit");
            reset({
                hdnField_productId: productData.id,
                categoryId: productData.cat_id,
                sub_categoryId: productData.sub_cat_id,
                productName: productData.name,
                productSlug: productData.slug,
                shortDescription: productData.short_description,
                productPrice: productData.price,
                productMrp: productData.mrp,
                productQty: productData.qty,
                productSKU: productData.sku_code,
                productHSN: productData.hsn_code,
                productWeight: productData.product_weight,
                shippingWeight: productData.shipping_weight,
                dispatchTime: productData.dispatch_time,
                deliveryTime: productData.delivery_time,
                brandId: productData.brand_id,
                gstPercent: productData.gst,
                packedBy: productData.packed_by,
                manufactureBy: productData.manufactured_by,
                productMaterial: productData.product_material,
                productVideoLink: productData.video_link,
                sizeId: productData.size_id,
                colorId: productData.color_id
            });
            setTags(productData.tags ? productData.tags.split(',') : []);
            setEditorHtml(productData.description);
            setIsCod(productData.cod === 1);
            setIsBestSeller(productData.best_seller === 1);
            setIsFeatured(productData.featured_products === 1);
            setIsDiscounted(productData.discounted_products === 1);
            setIsNewArrival(productData.new_arrival === 1);
            setIsActive(productData.is_active === 1);
            if (productData.all_images && productData.all_image_ids) {
                setUploadedImages(productData.all_images);
                setUploadedImagesId(productData.all_image_ids);
            }
        }
        else {
            handleReset();
            reset({
                hdnField_productId: "",
                categoryId: "",
                sub_categoryId: "",
                manufactureBy: "",
                productMaterial: ""
            });
        }

    }, [mode, reset]);

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("product_data");
        };
    }, []);

    const handleFormSubmit = async (data) => {
        data.images = files;
        data.description = editorHtml;        
        data.tags = tags.join(',');   // Convert tags array to a comma-separated string
        data.iscod = iscod ? "1" : "0";
        data.isBestSeller = isBestSeller ? "1" : "0";
        data.isFeatured = isFeatured ? "1" : "0";
        data.isDiscounted = isDiscounted ? "1" : "0";
        data.isNewArrival = isNewArrival ? "1" : "0";
        data.is_Active = isActive ? "1" : "0";
        await ProductFormSubmit(data, mode);
        handleReset();
    }

    const handleReset = () => {
        reset();
        setMode("Add");
        setEditorHtml('');
        setTags([]);
        setTagInput('');
        setIsCod(false);
        setIsBestSeller(false);
        setIsFeatured(false);
        setIsDiscounted(false);
        setIsNewArrival(false);
        setIsActive(false);
        setFiles([]);
        setUploadedImages([]);
        setUploadedImagesId([]);
        const productData = sessionStorage.getItem("product_data");
        if (productData) {
            sessionStorage.removeItem("product_data");
        }
    };

    const handleDeleteImage = async (index) => {
        const updatedImages = uploadedImages.split(',');
        const updatedIds = uploadedImagesId.split(',');

        const imageIdToDelete = updatedIds[index];
        await DeleteProductImage(imageIdToDelete);

        updatedImages.splice(index, 1);
        updatedIds.splice(index, 1);

        setUploadedImages(updatedImages.join(','));
        setUploadedImagesId(updatedIds.join(','));
        console.log(uploadedImages);
        console.log(uploadedImagesId);
    }


    return (
        <>
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="py-3 mb-0">
                        <span className="text-muted fw-light">eCommerce /</span><span className="fw-medium"> {mode === "Add" ? "Add" : "Edit"} Product</span>
                    </h4>
                    <div className="app-ecommerce">
                        { /* Add Product */}
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-1 mt-3">{mode === "Add" ? "Add a new" : "Edit"} Product</h4>
                                <p className="text-muted">Orders placed across your store</p>
                            </div>
                            <div className="d-flex align-content-center flex-wrap gap-3">
                                <Link to="/products-list" className="btn btn-primary waves-effect waves-light">View Products</Link>
                            </div>
                        </div>
                    </div>
                    <form id='productForm' onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="row">
                            { /* First column*/}
                            <div className="col-12 col-lg-8">
                                { /* Product Information */}
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="card-tile mb-0">Product information</h5>
                                    </div>
                                    <div className="card-body">

                                        { /* Hidden Field for Product ID */}
                                        <input type="hidden" id="productId" {...register("hdnField_productId")} />

                                        <div className="row">
                                            <div className="col">
                                                <CategoryDropDown register={register} errors={errors} />
                                            </div>
                                            <div className="col">
                                                <SubCategoryDropDown register={register} errors={errors} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <InputField label="Product Name" id="product-name" type="text" placeholder="Enter product name" name="productName" register={register} errors={errors} required />
                                            </div>
                                            <div className="col">
                                                <InputField label="Product Slug" id="product-slug" type="text" placeholder="Enter product slug" name="productSlug" register={register} errors={errors} required />
                                            </div>
                                        </div>

                                        { /* Short Description */}
                                        <TextArea label="Short Description" name="shortDescription" register={register} errors={errors} required />

                                        { /* Description */}
                                        <TextEditor editorHtml={editorHtml} setEditorHtml={setEditorHtml} />

                                        { /* Video Link */}
                                        <InputField label="Video Link" id="product-video-link" type="text" placeholder="Enter Video Link" name="productVideoLink" register={register} errors={errors} videolink />

                                        <div className="row">
                                            <div className="col">
                                                <InputField label="Price" id="product-price" type="number" placeholder="Enter product price" name="productPrice" register={register} errors={errors} required />
                                            </div>
                                            <div className="col">
                                                <InputField label="Mrp" id="product-mrp" type="number" placeholder="Enter product mrp" name="productMrp" register={register} errors={errors} required />
                                            </div>
                                            <div className="col">
                                                <InputField label="Quantity" id="product-qty" type="number" placeholder="Enter product qty" name="productQty" register={register} errors={errors} required />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <ColorDropDown register={register} errors={errors} />
                                            </div>
                                            <div className="col">
                                                <SizeDropDown register={register} errors={errors} />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col'>
                                                <label htmlFor="tag-input" className="form-label">Tags</label>
                                                <input
                                                    type="text"
                                                    id="tag-input"
                                                    className="form-control"
                                                    placeholder="Press Enter or Comma to add a tag"
                                                    value={tagInput}
                                                    onChange={(e) => setTagInput(e.target.value)}
                                                    onKeyDown={handleTagInput}
                                                />
                                                <div className="tags-list mt-2">
                                                    {tags.map((tag, index) => (                                                       
                                                        <span
                                                            key={index}
                                                            className="badge bg-primary me-2 mb-2 d-inline-flex align-items-center justify-content-between" style={{ padding: '0.5rem', gap: '0.5rem' }}>
                                                            {tag}
                                                            <button type="button" className="btn-close btn-close-white"
                                                                onClick={() => removeTag(index)} aria-label="Close"
                                                                 style={{ fontSize: '0.4rem', width: '10px', height: '10px' }}></button>
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /* /Product Information */}

                                { /* Media */}

                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0 card-title">Media</h5>
                                        <a href="javascript:void(0);" className="fw-medium">Add media from URL</a>
                                    </div>
                                    <div className="card-body">
                                        <DropZone files={files} setFiles={setFiles} />
                                    </div>
                                </div>
                                { /* /Media */}


                                { /* Images */}
                                {uploadedImages.length > 0 && (
                                    <div className="card mb-4">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0 card-title">Uploaded Images</h5>
                                        </div>
                                        <div className="card-body">
                                            {/* Render the images here */}
                                            {uploadedImages.split(',').map((image, index) => (
                                                <div className="d-inline-block" key={index}>
                                                    <div className="dz-preview">
                                                        <div className="dz-details">
                                                            <div className="dz-thumbnail">
                                                                <img src={`${PRODUCT_IMAGE_BASE_URL}${image}`} alt="img" />
                                                            </div>
                                                            <div className="dz-filename">
                                                                {image}
                                                            </div>
                                                            <div className="dz-size"></div>
                                                            <a type="button" className='dz-remove errorMsg' onClick={() => handleDeleteImage(index)}>Remove File</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                { /* /Images */}

                            </div>



                            { /* Second column */}
                            <div className="col-12 col-lg-4">
                                { /* Details Card */}
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Details</h5>
                                    </div>
                                    <div className="card-body">
                                        { /* SKU Code */}
                                        <InputField label="SKU Code" id="product-sku" type="text" placeholder="Enter product SKU Code" name="productSKU" register={register} errors={errors} required />

                                        { /* HSN Code  */}
                                        <InputField label="HSN Code" id="product-hsn" type="text" placeholder="Enter product HSN Code" name="productHSN" register={register} errors={errors} required />

                                        { /* Product Weight*/}
                                        <InputField label="Product Weight" id="product-weight" type="number" placeholder="Enter product weight" name="productWeight" register={register} errors={errors} required />

                                        { /* Shipping Weight*/}
                                        <InputField label="Shipping Weight" id="shipping-weight" type="number" placeholder="Enter shipping weight" name="shippingWeight" register={register} errors={errors} required />

                                        { /* Dispatch Time */}
                                        <InputField label="Dispatch Time" id="dispatch-time" type="number" placeholder="Enter dispatch time" name="dispatchTime" register={register} errors={errors} required />

                                        { /* Delivery Time*/}
                                        <InputField label="Delivery Time" id="delivery-time" type="number" placeholder="Enter devivery time" name="deliveryTime" register={register} errors={errors} required />

                                    </div>
                                </div>
                                { /* /Details Card */}

                                { /* Additional Details Card */}
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0"> Additional Details</h5>
                                    </div>
                                    <div className="card-body">
                                        { /* GST */}
                                        <InputField label="GST Percent" id="gst-percent" type="number" placeholder="Enter GST percent" name="gstPercent" register={register} errors={errors} required />

                                        { /* Product Brand DropDown */}
                                        <BrandDropDown register={register} errors={errors} />

                                        { /* Packed By */}
                                        <InputField label="Packed By" id="packed-by" type="text" placeholder="Enter packed by name" name="packedBy" register={register} errors={errors} required />

                                        { /* Manufacture By */}
                                        <InputField label="Manufacture By" id="manufacture-by" type="text" placeholder="Enter manufacturer name" name="manufactureBy" register={register} errors={errors} required />

                                        { /* Product Material */}
                                        <InputField label="Product Material" id="product-material" type="text" placeholder="Enter product material" name="productMaterial" register={register} errors={errors} required />
                                    </div>
                                </div>
                                { /* Additional Details Card */}

                                { /* Organize Card */}
                                <div className="card mb-4">
                                    <div className="card-body">
                                        { /* Cod switch */}
                                        <ToggleSwitch label="Is Cod Avilable" checked={iscod} onChange={(value) => setIsCod(value)} name="cod" />

                                        { /*  Best Seller switch */}
                                        <ToggleSwitch label="Is Best Seller" checked={isBestSeller} onChange={(value) => setIsBestSeller(value)} name="bestSeller" />

                                        { /* Featured switch */}
                                        <ToggleSwitch label="Is Featured" checked={isFeatured} onChange={(value) => setIsFeatured(value)} name="featured" />

                                        { /* Discounted switch */}
                                        <ToggleSwitch label="Is Discounted" checked={isDiscounted} onChange={(value) => setIsDiscounted(value)} name="discounted" />

                                        { /* New Arrival switch */}
                                        <ToggleSwitch label="Is New Arrival" checked={isNewArrival} onChange={(value) => setIsNewArrival(value)} name="newArrival" />

                                        { /* Active switch */}
                                        <ToggleSwitch label="Is Active" checked={isActive} onChange={(value) => setIsActive(value)} name="isActive" />
                                    </div>
                                </div>
                                { /* /Organize Card */}
                                <div className="d-flex justify-content-end gap-3">
                                    <button type="reset" className="btn btn-label-secondary waves-effect" onClick={handleReset}>Discard</button>
                                    <button type='submit' className="btn btn-primary waves-effect waves-light">{mode === "Add" ? "Save" : "Update"}</button>
                                </div>
                            </div>
                            { /* /Second column */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddProduct;