import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import InputField from '../Shared/Input/FormField';
import StatusDropdown from '../Shared/Input/StatusDropdown';
import { ColorFormSubmit } from './ColorFunction';

const ColorModal = ({ selectedRowData, setSelectedRowData, isColorUpdate, setIsColorUpdate }) => {

    const [mode, setMode] = useState("Add");
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        if (selectedRowData) {
            setMode("Edit");
            reset({
                hdnField_colorId: selectedRowData.id,
                colorName: selectedRowData.color,
                colorCode: selectedRowData.colorCode || "#000000",
                colorStatus: selectedRowData.is_active.toString()
            });
        }
        else {
            setMode("Add");
            reset({
                hdnField_colorId: "",
                colorName: "",
                colorCode: "#000000",
                colorStatus: ""
            });
        }
    }, [selectedRowData, reset]);


    const handleFormSubmit = async (data) => {
        console.log(data);
        await ColorFormSubmit(data, mode);
        handleReset();
        setIsColorUpdate(!isColorUpdate);
    }

    const handleReset = () => {
        reset();
        setMode("Add");
        setSelectedRowData(null);
        document.getElementById('offcanvasColor').classList.remove('show');
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop.fade.show');
        if (offcanvasBackdrop) {
            offcanvasBackdrop.remove();
        }
    };

    return (
        <>
            { /* Offcanvas to add new customer */}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasColor" aria-labelledby="offcanvasColorLabel" data-bs-backdrop="static" data-bs-scroll="true" >
                { /* Offcanvas Header */}
                <div className="offcanvas-header py-4">
                    <h5 id="offcanvasColorLabel" className="offcanvas-title">{mode === "Add" ? "Add" : "Edit"} Color</h5>
                    <button type="button" className="btn-close bg-label-secondary text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleReset} />
                </div>
                { /* Offcanvas Body */}
                <div className="offcanvas-body border-top">
                    <form className="pt-0" id="ColorForm" onSubmit={handleSubmit(handleFormSubmit)}>

                        { /* Hidden Field for Color Table Row ID */}
                        <input type="hidden" id="color-Id" {...register("hdnField_colorId")} />

                        { /* Color Name */}
                        <InputField label="Name" id="color-name" type="text" placeholder="Enter Color Name" name="colorName" register={register} errors={errors} required />

                        <InputField label="Color" id="color-code" type="color" name="colorCode" register={register} error={errors} required/>
                        
                        { /* Status */}
                        <StatusDropdown label="Select Color Status" id="color-status" name="colorStatus" register={register} errors={errors} />

                        { /* Submit and reset */}
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 data-submit">{mode === "Add" ? "Add" : "Update"}</button>
                            <button type="reset" className="btn bg-label-danger" data-bs-dismiss="offcanvas" onClick={handleReset}>Discard</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ColorModal