import { fetchData } from "../../API/ApiService";
import { API_URLS } from "../../API/apiUrls";
import { toast } from "react-toastify";

// handle image onchange event.
export const handleImageFileChange = (event, setImageError, trigger, setImagePreview) => {
    const file = event.target.files[0];
    if (file) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
        const fileType = file.type;
        if (!allowedTypes.includes(fileType)) {
            setImageError('Only PNG, JPG, JPEG, and WebP images are allowed');
            trigger("categoryImage");  // Re-run the image input validation.
        } else {
            if (file.size > 500 * 1024) {
                setImageError('Image size should not exceed 500KB');
                trigger("categoryImage");
            }
            else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = function () {
                        if (this.width === 130 && this.height === 130) {
                            setImageError('');
                            trigger("categoryImage");
                            setImagePreview(reader.result);
                        } else {
                            setImageError("Image dimensions should be 130x130 pixels");
                            trigger("categoryImage");
                        }
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    }
};



// handle form submit event.
export const CategoryFormSubmit = async (data, setImageError, mode) => {
    console.log(data);
    if (!data.categoryImage) {
        setImageError('Select Image First');
    }
    else {
        try {
            if (mode === "Add") {

                const formData = new FormData();
                formData.append('Name', data.categoryTitle);
                formData.append('Slug', data.categorySlug);
                formData.append('Image', data.categoryImage[0]);
                formData.append('Status', data.categoryStatus);

                const response = await fetch(API_URLS.insertCategoryData, {
                    method: 'POST',
                    body: formData
                });

                const responseData = await response.json();
                if (response.status === 200) {
                    if (responseData.responseCode === 1) {
                        toast.success(responseData.responseMessage);
                    }
                    else {
                        toast.error(responseData.responseMessage);
                    }
                }
            }
            else if (mode === "Edit") {

                const formData = new FormData();
                formData.append('ID', data.categoryId);
                formData.append('Name', data.categoryTitle);
                formData.append('Slug', data.categorySlug);
                formData.append('Image', data.categoryImage[0]);

                const response = await fetch(API_URLS.updateCategory, {
                    method: 'POST',
                    body: formData
                });
                const responseData = await response.json();
                if (response.status === 200) {
                    if (responseData.responseCode === 1) {
                        toast.success(responseData.responseMessage);
                    }
                    else {
                        toast.error(responseData.responseMessage);
                    }
                }
                else{
                    toast.error(responseData.error);
                }
            }
        }
        catch (error) {
            if (error.response && error.response.status === 401) {          // Handle 401 error                    
                alert("User not authenticated. Please login first.");
                window.location.href = "/login";
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
    }
};


// Method to Delete Category.
export const DeleteCategory = async (id) => {
    const requestBody = {
        ID: id
    };
    try {
        const response = await fetchData(API_URLS.deleteCategory, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }

    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}
