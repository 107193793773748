import { fetchData } from "../../API/ApiService";
import { API_URLS } from "../../API/apiUrls";
import { toast } from 'react-toastify';

// Method to Delete Category.
export const OrderStatusFormSubmit = async (data) => {
    const requestBody = {
        orderID: data.order_id,
        orderStatusID: data.order_status,
        trackingID: data.tracking_id,
        courierName: data.courier_name,
        reason: data.reason,
    };
    try {
        const response = await fetchData(API_URLS.updateOrderStatus, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }

    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}