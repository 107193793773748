import React from 'react'
import OrderItem from './OrderItem'

const OrderDetailTable = ({ orderDetail }) => {
    return (
        <div className="card mb-4">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="card-title m-0">Order details</h5>
            </div>
            <div className="card-datatable table-responsive">
                <table className="datatables-order-details table border-top">
                    <thead>
                        <tr>
                            <th>Sr.</th>
                            <th className="w-50 text-center">products</th>
                            <th className="text-center">price</th>
                            <th className="text-center">qty</th>
                            <th className="text-end">total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetail.map((orderItem, index) => (
                            <OrderItem key={index} orderItem={orderItem} index={index} />
                        ))}
                    </tbody>
                </table>
                <div className="d-flex justify-content-end align-items-center m-3 mb-2 p-1">
                    <div className="order-calculations">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="w-px-200 text-heading">Subtotal :</span>
                            <h6 className="mb-0">₹ {orderDetail.reduce((acc, item) => acc + (item.price * item.qty), 0).toFixed(2)}</h6>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span className="w-px-200 text-heading">GST(Included) :</span>
                            <h6 className="mb-0">₹ {orderDetail[0].gst.toFixed(2)}</h6>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span className="w-px-200 text-heading">Shipping :</span>
                            <h6 className="mb-0">₹ {orderDetail[0].shipping.toFixed(2)}</h6>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h6 className="w-px-200 mb-0">Total :</h6>
                            <h6 className="mb-0">₹ {orderDetail[0].total_amount.toFixed(2)}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetailTable