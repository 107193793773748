import { toast } from "react-toastify";
import { fetchData } from "./ApiService";
import { API_URLS } from "./apiUrls";

// Function TO change Status Active/Deactive.
export const ToggleActiveDeactivateStatus = async (id, status, tableName) => {
    const requestBody = {
        ID: id,
        Status: status,
        TableName: tableName
    };
    try {
        const response = await fetchData(API_URLS.toggleStatus, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            } else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}