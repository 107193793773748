import { toast } from "react-toastify";
import { fetchData } from "../../API/ApiService";
import { API_URLS } from "../../API/apiUrls";



// Method to Delete Color.
export const DeleteColor = async (id) => {
    const requestBody = {
        ColorId: id
    };
    try {
        const response = await fetchData(API_URLS.deleteColor, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}


// function to handle form add or update Data on submit click.
export const ColorFormSubmit = async (data, mode) => {
    try {
        if (mode === "Add") {
            const requestBody = {
                ColorName: data.colorName,
                ColorCode: data.colorCode,
                Status: data.colorStatus
            }
            const response = await fetchData(API_URLS.insertNewColor, requestBody);
            const responseData = response.data;
            if (response.status === 200) {
                if (responseData.responseCode === 1) {
                    toast.success(responseData.responseMessage);
                }
                else {
                    toast.error(responseData.responseMessage);
                }
            }
        }
        else if (mode === "Edit") {
            const requestBody = {
                ColorId: data.hdnField_colorId,
                ColorName: data.colorName,
                ColorCode: data.colorCode,
                Status: data.colorStatus
            }
            const response = await fetchData(API_URLS.updateColor, requestBody);
            const responseData = response.data;
            if (response.status === 200) {
                if (responseData.responseCode === 1) {
                    toast.success(responseData.responseMessage);
                }
                else {
                    toast.error(responseData.responseMessage);
                }
            }
        }
    }
    catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
};