import React from 'react'

const TextArea = ({ label, name, register, errors, required }) => {
    return (
        <>
            <div className="mb-3">
                <label className="form-label">{label}</label>
                <textarea id="bootstrap-maxlength-example2" className="form-control bootstrap-maxlength-example" rows={3} maxLength={255}
                    {...register(name, { ...(required && { required: `${label} is required.` }) })}
                />
                {errors[name] && <p className="errorMsg">{errors[name].message}</p>}
            </div>
        </>
    )
}

export default TextArea