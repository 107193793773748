import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import CategoryDropDown from '../Shared/DropDown/CategoryDropDown';
import InputField from '../Shared/Input/FormField';
import StatusDropdown from '../Shared/Input/StatusDropdown';
import { SubCategoryFormSubmit } from './SubCategoryFunction';

const SubCategoryModal = ({ selectedSubCategory, setSelectedSubCategory, isSubCategoryUpdate, setisSubCategoryUpdate }) => {

    const [mode, setMode] = useState("Add");
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        if (selectedSubCategory) {
            setMode("Edit");
            reset({
                subCategoryId: selectedSubCategory.id,
                categoryId: selectedSubCategory.cat_id,
                subcategoryTitle: selectedSubCategory.sub_category_name,
                subCategorySlug: selectedSubCategory.slug,
                categoryStatus: selectedSubCategory.is_active.toString()
            });
        }
        else {
            setMode("Add");
            reset({
                subCategoryId: "",
                categoryId: "",
                subcategoryTitle: "",
                subCategorySlug: "",
                categoryStatus: ""
            });
        }
    }, [selectedSubCategory, reset]);


    const handleFormSubmit = async (data) => {
        console.log(data);
        await SubCategoryFormSubmit(data, mode);
        handleReset();
        setisSubCategoryUpdate(!isSubCategoryUpdate);
    }

    const handleReset = () => {
        reset();
        setMode("Add");
        setSelectedSubCategory(null);
        document.getElementById('offcanvasSubCategory').classList.remove('show');
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop.fade.show');
        if (offcanvasBackdrop) {
            offcanvasBackdrop.remove();
        }
    };


    return (
        <>
            { /* Offcanvas to add new customer */}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasSubCategory" aria-labelledby="offcanvasSubCategoryLabel" data-bs-backdrop="static" data-bs-scroll="true" >
                { /* Offcanvas Header */}
                <div className="offcanvas-header py-4">
                    <h5 id="offcanvasSubCategoryLabel" className="offcanvas-title">{mode === "Add" ? "Add" : "Edit"} Sub Category</h5>
                    <button type="button" className="btn-close bg-label-secondary text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleReset} />
                </div>
                { /* Offcanvas Body */}
                <div className="offcanvas-body border-top">
                    <form className="pt-0" id="SubCategoryForm" onSubmit={handleSubmit(handleFormSubmit)}>

                        { /* Hidden Field for Category ID */}
                        <input type="hidden" id="sub-categoryId" {...register("subCategoryId")} />

                        { /* Category DropDown */}
                            <CategoryDropDown register={register} errors={errors} />

                        { /* Sub-Category Title */}
                            <InputField label="Title" id="sub-category-title" type="text" placeholder="Enter sub-category title" name="subcategoryTitle" register={register} errors={errors} required/>

                        { /* Slug */}
                            <InputField label="Slug" id="sub-category-slug" type="text" placeholder="Enter sub-category slug" name="subCategorySlug" register={register} errors={errors} required/>

                        { /* Status */}
                            <StatusDropdown label="Select Category Status" id="category-status" name="categoryStatus" register={register} errors={errors} />

                        { /* Submit and reset */}
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 data-submit">{mode === "Add" ? "Add" : "Update"}</button>
                            <button type="reset" className="btn bg-label-danger" data-bs-dismiss="offcanvas" onClick={handleReset}>Discard</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SubCategoryModal