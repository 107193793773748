import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

const TextEditor = ({editorHtml, setEditorHtml }) => {


    const handleEditorChange = (html) => {
        setEditorHtml(html);
    };

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const editorOptions = {
        modules: {
            toolbar: toolbarOptions
        },
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ]
    };


    return (
        <div className='mb-3'>
            <label className="form-label">Description (Optional)</label>
            <ReactQuill theme="snow" value={editorHtml} onChange={handleEditorChange} modules={editorOptions.modules} formats={editorOptions.formats} />
        </div>
    )
}

export default TextEditor