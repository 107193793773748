import React, { useEffect, useState } from 'react'
import VariationList from './VariationList'
import { Link, useNavigate } from 'react-router-dom'
import VariationForm from './VariationForm'
import { fetchData } from '../../API/ApiService'
import { API_URLS, PRODUCT_IMAGE_BASE_URL } from '../../API/apiUrls'
import { toast } from "react-toastify";

const Variation = () => {
    const navigate = useNavigate();
    const [mode, setMode] = useState("Add");
    const [productDetail, setProductDetail] = useState(null);
    const [defaultImage, setDefaultImage] = useState('');
    const [defaultImageId, setDefaultImageId] = useState('');
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isVariationUpdate, setisVariationUpdate] = useState(false);

    useEffect(() => {
        const product_id = sessionStorage.getItem("product_Id") || null;
        if (product_id !== null) {
            const fetchProductDetailById = async () => {
                const requestBody = {
                    ProductId: product_id
                };
                try {
                    const response = await fetchData(API_URLS.fetchProductImageById, requestBody);
                    const data = response.data;
                    if (response.status === 200) {
                        if (data.responseCode === 1) {
                            const responseData = JSON.parse(data.responseData);
                            setProductDetail(responseData || null);

                            if (responseData && responseData.length > 0) {
                                const firstItem = responseData[0];
                                const imageIds = firstItem.product_images_id.split(',');
                                const images = firstItem.product_images.split(',');
                                const firstImageId = imageIds[0];
                                const firstImage = images[0];
                                setDefaultImage(firstImage);
                                setDefaultImageId(firstImageId);
                            }
                        }
                        else {
                            toast.error(data.responseMessage);
                        }
                    }
                } catch (error) {
                    if (error.response && error.response.status === 401) {          // Handle 401 error                    
                        alert("User not authenticated. Please login first.");
                        window.location.href = "/login";
                    } else {
                        toast.error(`Error: ${error.message}`);
                    }
                }
            };
            fetchProductDetailById();
        }
        else {
            navigate('/products-list');
        }
    }, [navigate]);


    useEffect(() => {
        return () => {
            sessionStorage.removeItem("product_Id");
        };
    }, []);


    const handleImageClick = (image, id) => {
        setDefaultImage(image);
        setDefaultImageId(id);
    };


    return (
        <>
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <h4 className="py-3 mb-2">
                                <span className="text-muted fw-light">eCommerce /</span> Variation
                            </h4>
                        </div>
                        <div>
                            <Link to="/products-list" className="btn btn-primary waves-effect waves-light">View Products</Link>
                        </div>
                    </div>
                    <VariationForm DefaultImage={defaultImage} setDefaultImage={setDefaultImage} setDefaultImageId={setDefaultImageId} DefaultImageId={defaultImageId} ProductDetail={productDetail} mode={mode} setMode={setMode} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} isVariationUpdate={isVariationUpdate} setisVariationUpdate={setisVariationUpdate}/>
                </div>
                <VariationList selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} isVariationUpdate={isVariationUpdate} setisVariationUpdate={setisVariationUpdate}/>
            </div>

            { /* Modal */}
            <div className="modal fade" id="modalCenter" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalCenterTitle">Select Image</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='d-flex justify-content-center'>
                                {productDetail && productDetail.map((item, index) => {
                                    const imageIds = item.product_images_id.split(',');
                                    const images = item.product_images.split(',');
                                    return (
                                        <div key={index} className='d-flex'>
                                            {images.map((image, imageIndex) => (
                                                <div className="relative" key={imageIndex} onClick={() => handleImageClick(image, imageIds[imageIndex])}>
                                                    <img className="inline-flex border rounded-md border-gray-100 dark:border-gray-600 w-24 max-h-24 p-2 m-2" src={`${PRODUCT_IMAGE_BASE_URL}${image}`} alt="product" />
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Variation