import React, { useEffect, useState } from 'react'
import InputField from '../Shared/Input/FormField';
import { useForm } from 'react-hook-form';
import { PRODUCT_IMAGE_BASE_URL } from '../../API/apiUrls';
import { VariationFormSubmit } from './VariationFunction';
import ColorDropDown from '../Shared/DropDown/ColorDropDown';
import SizeDropDown from '../Shared/DropDown/SizeDropDown';


const VariationForm = ({ DefaultImage, setDefaultImage, DefaultImageId, setDefaultImageId, ProductDetail, mode, setMode, selectedRowData, setSelectedRowData, isVariationUpdate, setisVariationUpdate }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        if (ProductDetail) {
            reset({
                hdnField_productId: ProductDetail[0].id
            })
        }
    }, [ProductDetail, reset]);


    useEffect(() => {
        if (selectedRowData) {
            setMode("Edit");
            reset({
                hdnField_varationId: selectedRowData.id,
                sizeId: selectedRowData.size_id,
                colorId: selectedRowData.color_id,
                productMrp: selectedRowData.mrp,
                productPrice: selectedRowData.price,
                productQty: selectedRowData.qty
            });
            setDefaultImageId(selectedRowData.image_id);
            const id = selectedRowData.image_id;
            const ids = ProductDetail[0].product_images_id;
            const idArray = ids.split(",").map(Number);
            const index = idArray.indexOf(parseInt(id));
            if (index !== -1) {
                const images = ProductDetail[0].product_images;
                const ImageArray = images.split(",").map(String);
                const selectedImage = ImageArray[index];
                setDefaultImage(selectedImage);
            }
        }
        else {
            setMode("Add");
            reset({
                hdnField_varationId: "",
                sizeId: "",
                colorId: "",
                productMrp: "",
                productPrice: "",
                productQty: ""
            });
        }
    }, [selectedRowData, reset, setMode, setDefaultImageId]);


    const handleFormSubmit = async (data) => {
        data.imageId = DefaultImageId;
        await VariationFormSubmit(data, mode);
        handleReset();
        setisVariationUpdate(!isVariationUpdate);
    }

    const handleReset = () => {
        reset();
        setMode("Add");
        setSelectedRowData(null);
    }

    return (
        <>
            <form id="variationForm" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="card-header">
                                <h5 className="card-tile mb-0">{mode === "Add" ? "Add New" : "Edit"} Variation</h5>
                            </div>
                            <div className="card-body">
                                { /* Hidden Field for Product ID */}
                                <input type="hidden" id="productId" {...register("hdnField_productId")} />

                                { /* Hidden Field for Variation ID */}
                                <input type="hidden" id="variationId" {...register("hdnField_varationId")} />

                                <div className="row">
                                    <div className="col-2">
                                        <ColorDropDown register={register} errors={errors} />
                                    </div>
                                    <div className="col-2">
                                        <SizeDropDown register={register} errors={errors} />
                                    </div>
                                    <div className="col-2">
                                        <InputField label="Price" id="product-price" type="number" placeholder="Enter product price" name="productPrice" register={register} errors={errors} required />
                                    </div>
                                    <div className="col-2">
                                        <InputField label="Mrp" id="product-mrp" type="number" placeholder="Enter product mrp" name="productMrp" register={register} errors={errors} required />
                                    </div>
                                    <div className="col-2">
                                        <InputField label="Quantity" id="product-qty" type="number" placeholder="Enter product qty" name="productQty" register={register} errors={errors} required />
                                    </div>
                                    <div className="col-2">
                                        <div className="d-flex text-center ">
                                            <span>
                                                <div className="position-relative inline-block w-8 h-8 shadow-none">
                                                    <img className="object-cover w-full h-full" src={`${PRODUCT_IMAGE_BASE_URL}${DefaultImage}`} alt="product" loading="lazy" />
                                                    <div className="absolute inset-0 shadow-inner" aria-hidden="true" />
                                                </div>
                                                <a href='!#' className="text-xs cursor-pointer" data-bs-toggle="modal" data-bs-target="#modalCenter">Change</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-4 float-end">
                                    <button type="reset" className="btn btn-label-secondary me-sm-3 me-1 waves-effect" onClick={handleReset}>Cancel</button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light">{mode === "Add" ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default VariationForm