import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/page-auth.css';
import { useForm } from "react-hook-form";
import EmailInput from '../../Shared/Input/EmailInput';
import PasswordInput from '../../Shared/Input/PasswordInput';
import { fetchData } from '../../../API/ApiService';
import { API_URLS } from '../../../API/apiUrls';
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const handleLogin = async (data) => {

        const requestBody = {
            email: data.email,
            passWord: data.password
        };

        try {
            const response = await fetchData(API_URLS.login, requestBody);
            const data = response.data;
            if (data.responseCode === 1) {
                const responseData = JSON.parse(data.responseData)[0];
                sessionStorage.setItem("authToken", data.token);
                sessionStorage.setItem("admin_id", responseData.id);
                sessionStorage.setItem("admin_name", responseData.name);
                sessionStorage.setItem("login_message", data.responseMessage);
                navigate("/dashboard");

            } else {
                toast.warning(data.responseMessage);
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    }


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={5}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className="authentication-wrapper authentication-cover authentication-bg">
                <div className="authentication-inner row">
                    { /* /Left Text */}
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img src="/assets/img/auth/login-img.png" alt="auth-login-cover" className="img-fluid my-5 auth-illustration" />
                            <img src="/assets/img/auth/login-bg-shape.png" alt="auth-login-cover" className="platform-bg" />
                        </div>
                    </div>
                    { /* /Left Text */}

                    { /* Login */}
                    <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            <h3 className="mb-1">Welcome to Admin 👋</h3>
                            <p className="mb-4">Please sign-in to your account and start the adventure</p>
                            <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(handleLogin)}>
                                <EmailInput register={register} errors={errors} />
                                <PasswordInput register={register} errors={errors} showForgotPassword={true} />
                                <button type="submit" className="btn btn-primary d-grid w-100">
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                    { /* /Login */}
                </div>
            </div>
        </>
    )
}

export default Login