import React, { useEffect, useState } from 'react';
import { fetchData } from '../../../API/ApiService';
import { API_URLS } from '../../../API/apiUrls';
import { toast } from 'react-toastify';

const ColorDropDown = ({ register, errors }) => {

    const [colorData, setColorData] = useState([]);

    useEffect(() => {
        const fetchColorData = async () => {
            try {
                const response = await fetchData(API_URLS.getAllActiveColor, {});
                const responseData = response.data;
                if (response.status === 200) {
                    if (responseData.responseCode === 1) {
                        const data = JSON.parse(responseData.responseData);
                        setColorData(data);
                    }
                    else {
                        toast.error(responseData.responseMessage);
                    }
                }
            }
            catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchColorData();
    }, []);

    return (
        <>
            { /* Color DropDown */}

            <div className="mb-3">
                <label className="form-label">Select Color</label>
                <select id="color-dropdown" className="form-select" {...register("colorId", {
                    required: "Please select a value"
                })} defaultValue="">
                    {colorData && colorData.length > 0 ? (
                        <>
                            <option value="" disabled>--Select--</option>
                            {colorData.map(color => (
                                <option key={color.id} value={color.id}>{color.color}</option>
                            ))}
                        </>
                    ) : (
                        <option value='' disabled>-- No Data Available --</option>
                    )}

                </select>
                {errors.colorId && <p className='errorMsg'>{errors.colorId.message}</p>}
            </div>

        </>
    )
}

export default ColorDropDown