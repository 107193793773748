import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../API/ApiService';
import { API_URLS } from '../../../API/apiUrls';
import { toast } from 'react-toastify';

const SubCategoryDropDown = ({ register, errors }) => {

    const [subCategories, setSubCategories] = useState([]);

    useEffect(() => {
        const fetchSubCategoriesData = async () => {
            try {
                const response = await fetchData(API_URLS.fetchActiveSubCategories, {});
                const responseData = response.data;
                if (response.status === 200) {
                    if (responseData.responseCode === 1) {
                        const data = JSON.parse(responseData.responseData);
                        setSubCategories(data);
                    }
                    else {
                        toast.error(responseData.responseMessage);
                    }
                }
            }
            catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchSubCategoriesData();
    }, []);

    return (
        <>
            { /* Category DropDown */}

            <div className="mb-3">
                <label className="form-label">Select Sub-category</label>
                <select id="sub-category-dropdown" className="form-select" {...register("sub_categoryId", {
                    required: "Please select a value"
                })} defaultValue="">
                    {subCategories && subCategories.length > 0 ? (
                        <>
                            <option value="" disabled>--Select--</option>
                            {subCategories.map(subcategory => (
                                <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                            ))}
                        </>
                    ) : (
                        <option value='' disabled>-- No Data Available --</option>
                    )}

                </select>
                {errors.sub_categoryId && <p className='errorMsg'>{errors.sub_categoryId.message}</p>}
            </div>

        </>
    )
}

export default SubCategoryDropDown