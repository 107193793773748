import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const PasswordInput = ({ register, errors, showForgotPassword }) => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    return (
        <>
            <div className="mb-3 form-password-toggle">
                <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">Password</label>
                    {showForgotPassword && (
                        <Link to="/forgot-password">
                            <small>Forgot Password?</small>
                        </Link>
                    )}
                </div>
                <div className="input-group input-group-merge">
                    <input type={showPassword ? "text" : "password"} id="password" className="form-control" name="password" placeholder="*******" aria-describedby="password" {...register("password", {
                        required: "Password is required"
                    })} />
                    <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}><i className={showPassword ? "ti ti-eye" : "ti ti-eye-off"} /></span>
                </div>
                {errors.password && <p className="errorMsg">{errors.password.message}</p>}
            </div>
        </>
    )
}

export default PasswordInput