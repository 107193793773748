import React from 'react'

const StatusDropdown = ({ label, id, name, register, errors }) => {
    return (
        <>
            <div className="mb-4">
                <label className="form-label" htmlFor={id}>{label}</label>
                <select id={id} className="form-select"  {...register(name, {
                    required: "Please select a value"
                })} defaultValue="">
                    <option value="" disabled >--Select--</option>
                    <option value="1">Publish</option>
                    <option value="0">Inactive</option>
                </select>
                {errors[name] && <p className="errorMsg">{errors[name].message}</p>}
            </div>
        </>
    )
}

export default StatusDropdown