import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { fetchData } from '../../API/ApiService';
import { API_URLS } from '../../API/apiUrls';
import OrderStatusModal from './OrderStatusModal';
import { Link } from 'react-router-dom';

const OrderList = () => {

    const [orderData, setOrderData] = useState([]);
    const [hdnFieldOrderID, setHdnFieldOrderID] = useState(null);
    const [isOrderUpdate, setIsOrderUpdate] = useState(false);

    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                const response = await fetchData(API_URLS.getOrders, {});
                const data = response.data;
                if (response.status === 200) {
                    if (data.responseCode === 1) {
                        const responseData = JSON.parse(data.responseData);
                        setOrderData(responseData);
                    }
                    else {
                        toast.error(`Error: ${data.responseMessage}`);
                    }
                }
            }
            catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchOrderData();
    }, [isOrderUpdate]);

    const handleStatusClick = (rowData) => {
        const order_id = rowData.order_id;
        setHdnFieldOrderID(order_id);
    };

    const columns = [
        {
            name: "order_id",
            label: "Order",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Link to={`/order-detail?id=${value}`}><span>#{value}</span></Link>
                    );
                },
            }
        },
        {
            name: "order_date",
            label: "Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const date = new Date(value);
                    const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
                    const formattedDate = `${monthAbbreviation} ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`;
                    return (
                        <h6 className="text-body text-nowrap mb-0">{formattedDate}</h6>
                    );
                },
            }
        },
        {
            name: "customer_name",
            label: "Customer",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const customer = orderData[tableMeta.rowIndex];
                    return (
                        <>
                            <div className="d-flex justify-content-center align-items-center product-name">
                                <div className="d-flex flex-column">
                                    <h6 className="text-body text-left text-nowrap mb-0">{customer.customer_name}</h6>
                                    <small className="text-muted text-left text-truncate d-none d-sm-block">{customer.customer_email}</small>
                                </div>
                            </div>
                        </>
                    );
                },
            }
        },
        {
            name: "total_amount",
            label: "Amount",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <h6 className="text-body text-nowrap mb-0">₹ {value}</h6>
                    );
                },
            }
        },
        {
            name: "payment_id",
            label: "Payment",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value === '0') {
                        return (
                            <h6 className="mb-0 align-items-center justify-content-center d-flex text-danger"><i className="ti ti-circle-filled fs-tiny me-2" />Failed</h6>
                        );
                    } else if (value === 'cod') {
                        return (
                            <h6 className="mb-0 align-items-center justify-content-center d-flex text-warning"><i className="ti ti-circle-filled fs-tiny me-2" />COD</h6>
                        );
                    } else {
                        return (
                            <h6 className="mb-0 align-items-center justify-content-center d-flex text-success"><i className="ti ti-circle-filled fs-tiny me-2" />Paid</h6>
                        );
                    }
                },
            }
        },
        {
            name: "order_status",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value === 0) {
                        return (
                            <span className="badge px-2 bg-label-info" text-capitalized="">Payment Failed</span>
                        );
                    } else if (value === 1) {
                        return (
                            <span className="badge px-2 bg-label-warning cursor-pointer" data-bs-toggle="modal" data-bs-target="#orderStatusChange" text-capitalized="" onClick={() => handleStatusClick(orderData[tableMeta.rowIndex])}>Processing</span>
                        );
                    } else if (value === 2) {
                        return (
                            <span className="badge px-2 bg-label-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#orderStatusChange" text-capitalized="" onClick={() => handleStatusClick(orderData[tableMeta.rowIndex])}>Dispached</span>
                        );
                    } else if (value === 3) {
                        return (
                            <span className="badge px-2 bg-label-success" text-capitalized="">Delivered</span>
                        );
                    } else if (value === 4) {
                        return (
                            <span className="badge px-2 bg-label-danger" text-capitalized="">Cancel By User</span>
                        );
                    } else if (value === 5) {
                        return (
                            <span className="badge px-2 bg-label-danger" text-capitalized="">Cancel By Admin</span>
                        );
                    }
                },
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="d-flex align-items-sm-center justify-content-sm-center">
                                <button className="btn btn-sm btn-icon delete-record">
                                    <i className="ti ti-trash" />
                                </button>
                            </div>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'multiselect',    // filtertype for filter icon dropdown
        responsive: 'standard',       // make table responsive
        filter: true,                 // show or hide the filter icon from top right side 
        selectableRows: false         // first column with checkbox
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="py-3 mb-2">
                    <span className="text-muted fw-light">eCommerce /</span> Order List
                </h4>

                <MUIDataTable
                    title={"Order List"}
                    data={orderData || []}
                    columns={columns}
                    options={options}
                />

                <OrderStatusModal hdnFieldOrderID={hdnFieldOrderID} isOrderUpdate={isOrderUpdate} setIsOrderUpdate={setIsOrderUpdate} />
            </div>
        </>
    )
}

export default OrderList