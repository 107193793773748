import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { CAT_IMAGE_BASE_URL } from "../../API/apiUrls";
import { handleImageFileChange, CategoryFormSubmit } from './CatFunction';
import InputField from '../Shared/Input/FormField';
import StatusDropdown from '../Shared/Input/StatusDropdown';

const CategoryModal = ({ selectedCategory, setSelectedCategory, isCategoryUpdate, setisCategoryUpdate }) => {

    const [imageError, setImageError] = useState("");
    const [imagePreview, setImagePreview] = useState(null);
    const [mode, setMode] = useState("Add");
    const { register, handleSubmit, trigger, reset, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        if (selectedCategory) {
            setMode("Edit");
            reset({
                categoryId: selectedCategory.id,
                categoryTitle: selectedCategory.name,
                categorySlug: selectedCategory.slug,
                categoryImage: `${CAT_IMAGE_BASE_URL}${selectedCategory.cat_image}`,
                categoryStatus: selectedCategory.is_active.toString()
            });
            if (selectedCategory.cat_image) {
                setImagePreview(`${CAT_IMAGE_BASE_URL}${selectedCategory.cat_image}`);
            }
        } else {
            setMode("Add");
            reset({
                categoryId: "",
                categoryTitle: "",
                categorySlug: "",
                categoryImage: "",
                categoryStatus: ""
            });
        }
    }, [selectedCategory, reset]);

    useEffect(() => {
        // Update categoryId value whenever selectedCategory changes
        if (selectedCategory) {
            setValue("categoryId", selectedCategory.id);
        }
    }, [selectedCategory, setValue]);




    const handleFormSubmit = async (data) => {
        console.log(data);
        await CategoryFormSubmit(data, setImageError, mode);
        handleReset();
        setisCategoryUpdate(!isCategoryUpdate);
    }

    const handleImgChange = (event) => {
        handleImageFileChange(event, setImageError, trigger, setImagePreview);
    }

    const handleReset = () => {
        reset();
        setImageError('');
        setImagePreview(null);
        setMode("Add");
        setSelectedCategory(null);
        document.getElementById('offcanvasCategory').classList.remove('show');
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop.fade.show');
        if (offcanvasBackdrop) {
            offcanvasBackdrop.remove();
        }
    };


    return (
        <>
            { /* Offcanvas to add new customer */}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasCategory" aria-labelledby="offcanvasEcommerceCategoryListLabel" data-bs-backdrop="static" data-bs-scroll="true" >
                { /* Offcanvas Header */}
                <div className="offcanvas-header py-4">
                    <h5 id="offcanvasEcommerceCategoryListLabel" className="offcanvas-title">{mode === "Add" ? "Add" : "Edit"} Category</h5>
                    <button type="button" className="btn-close bg-label-secondary text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleReset} />
                </div>
                { /* Offcanvas Body */}
                <div className="offcanvas-body border-top">
                    <form className="pt-0" id="eCommerceCategoryListForm" onSubmit={handleSubmit(handleFormSubmit)}>

                        { /* Hidden Field for Category ID */}
                        <input type="hidden" id="categoryId" {...register("categoryId")} />

                        { /* Title */}
                        <InputField label="Title" id="category-title" type="text" placeholder="Enter category title" name="categoryTitle" register={register} errors={errors} required />

                        { /* Slug */}
                        <InputField label="Slug" id="category-slug" type="text" placeholder="Enter category slug" name="categorySlug" register={register} errors={errors} required />


                        { /* Image */}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="category-image">Attachment <span className='errorMsg'>( Recommanded size 130 * 130 )</span></label>
                            <input className="form-control" type="file" id="category-image" {...register("categoryImage", {
                            })} onChange={handleImgChange} />
                            {imageError && <p className="errorMsg">{imageError}</p>}
                            {errors.categoryImage && <p className="errorMsg">{errors.categoryImage.message}</p>}
                            {imagePreview && (
                                <img src={imagePreview} alt="Img Preview" style={{ maxWidth: "100%", marginTop: "10px" }} />
                            )}
                        </div>

                        { /* Status */}
                        <StatusDropdown label="Select Category Status" id="category-status" name="categoryStatus" register={register} errors={errors} />

                        { /* Submit and reset */}
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 data-submit">{mode === "Add" ? "Add" : "Update"}</button>
                            <button type="reset" className="btn bg-label-danger" data-bs-dismiss="offcanvas" onClick={handleReset}>Discard</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

export default CategoryModal