import React from 'react'

const ToggleSwitch = ({ label, checked, onChange, name, register }) => {

    const handleToggle = (e) => {
        onChange(e.target.checked);
    };

    return (
        <div className="d-flex justify-content-between align-items-center pt-3">
            <h6 className="mb-0">{label}</h6>
            <div className="w-25 d-flex justify-content-end">
                <label className="switch switch-success me-4 pe-2">
                    <input
                        type="checkbox"
                        className="switch-input"
                        checked={checked}
                        onChange={handleToggle}
                    />
                    <span className="switch-toggle-slider">
                        <span className="switch-on">
                            <i className="ti ti-check" />
                        </span>
                        <span className="switch-off">
                            <i className="ti ti-x" />
                        </span>
                    </span>
                </label>
            </div>
        </div>
    )
}

export default ToggleSwitch