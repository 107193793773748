import './App.css';
import SideBarMenu from './Components/Layout/SideBarMenu';
import HeaderNav from './Components/Layout/Header/HeaderNav';
import Footer from './Components/Layout/Footer';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import CategoryList from './Components/Category/CategoryList';
import Login from './Components/Auth/Login/Login';
import AddProduct from './Components/Product/AddProduct';
import Dashboard from './Components/Dashboard/Dashboard';
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubCategoryList from './Components/SubCategory/SubCategoryList';
import ColorList from './Components/Color/ColorList';
import SizeList from './Components/Size/SizeList';
import ProductList from './Components/Product/ProductList';
import Variation from './Components/Variation/Variation';
import OrderList from './Components/Order/OrderList';
import OrderDetail from './Components/Order/OrderDetail';
import ForgotPassword from './Components/Auth/ForgotPassword';
import OtpVerification from './Components/Auth/OtpVerification';
import ResetPassword from './Components/Auth/ResetPassword';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp-verification' element={<OtpVerification />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        
        <Route path='/*' element={<ProtectedRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}


function ProtectedRoutes() {
  const isAuthenticated = sessionStorage.getItem("admin_id") !== null;
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={5}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBarMenu />
          <div className='layout-page'>
            <HeaderNav />
            <div className='content-wrapper'>
              <Routes>
                <Route path='/dashboard' exact element={<Dashboard />} />
                <Route path="/category" exact element={<CategoryList />} />
                <Route path='/sub-category' exact element={<SubCategoryList />} />
                <Route path='/color' exact element={<ColorList />} />
                <Route path='/size' exact element={<SizeList />} />
                <Route path="/add-product" exact element={<AddProduct />} />
                <Route path="/products-list" exact element={<ProductList />} />
                <Route path="/add-variation" exact element={<Variation />} />
                <Route path="/order-list" exact element={<OrderList />} />
                <Route path="/order-detail" exact element={<OrderDetail />} />

              </Routes>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default App;
