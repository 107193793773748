import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchData } from '../../API/ApiService';
import { API_URLS } from '../../API/apiUrls';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import { DeleteProduct, ToggleProductFeatureStatus } from './ProductFunction';
import { ToggleActiveDeactivateStatus } from '../../API/CommonFunction';
import { PRODUCT_IMAGE_BASE_URL } from "../../API/apiUrls";

const ProductList = () => {
    const navigate = useNavigate();
    const [productData, setProductData] = useState([]);
    const [isProductDataUpdate, setIsProductDataUpdate] = useState(false);

    useEffect(() => {
        const fetchProductsData = async () => {
            try {
                const response = await fetchData(API_URLS.fetchProducts, {});
                const data = response.data;
                if (response.status === 200) {
                    if (data.responseCode === 1) {
                        const responseData = JSON.parse(data.responseData);
                        setProductData(responseData);
                    }
                    else {
                        toast.error(data.responseMessage);
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchProductsData();
    }, [isProductDataUpdate]);

    const handleAddVariation = (rowData) => {
        const product_id = rowData.id;
        sessionStorage.setItem("product_Id", JSON.stringify(product_id));
        navigate('/add-variation');
    }


    const handleEditProduct = (rowData) => {
        const selectedRowData = rowData;
        sessionStorage.setItem("product_data", JSON.stringify(selectedRowData));
        navigate('/add-product');
    };

    const handleDeleteProduct = async (rowData) => {
        const product_id = rowData.id;
        await DeleteProduct(product_id);
        setIsProductDataUpdate(!isProductDataUpdate);
    };

    const handleStatusChange = async (rowData) => {
        const product_id = rowData.id;
        const tableName = "products";
        const status = rowData.is_active === 1 ? 0 : 1;
        await ToggleActiveDeactivateStatus(product_id, status, tableName);
        setIsProductDataUpdate(!isProductDataUpdate);
    }

    const handleBestSellerStatusChange = async (rowData) => {
        const product_id = rowData.id;
        const status = rowData.best_seller === 1 ? 0 : 1;
        const columnName = "best_seller";
        await ToggleProductFeatureStatus(product_id, status, columnName);
        setIsProductDataUpdate(!isProductDataUpdate);
    }

    const handleFeaturedStatusChange = async (rowData) => {
        const product_id = rowData.id;
        const status = rowData.featured_products === 1 ? 0 : 1;
        const columnName = "featured_products";
        await ToggleProductFeatureStatus(product_id, status, columnName);
        setIsProductDataUpdate(!isProductDataUpdate);
    }
    const handleDiscountStatusChange = async (rowData) => {
        const product_id = rowData.id;
        const status = rowData.discounted_products === 1 ? 0 : 1;
        const columnName = "discounted_products";
        await ToggleProductFeatureStatus(product_id, status, columnName);
        setIsProductDataUpdate(!isProductDataUpdate);
    }
    const handleNewArrivalStatusChange = async (rowData) => {
        const product_id = rowData.id;
        const status = rowData.new_arrival === 1 ? 0 : 1;
        const columnName = "new_arrival";
        await ToggleProductFeatureStatus(product_id, status, columnName);
        setIsProductDataUpdate(!isProductDataUpdate);
    }


    const columns = [
        {
            name: "image",
            label: "Product",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const product = productData[tableMeta.rowIndex];                   
                    return (
                        <>
                            <div className="d-flex justify-content-start align-items-center product-name">
                                <div className="avatar-wrapper">
                                    <div className="avatar avatar me-2 rounded-2 bg-label-secondary">
                                    <img src={`${PRODUCT_IMAGE_BASE_URL}${product.product_image}`} alt="Product" className="rounded-2" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column">
                                    <h6 className="text-body text-left text-nowrap mb-0">{product.name.split(' ').slice(0, 4).join(' ')}</h6>
                                    <small className="text-muted text-left text-truncate d-none d-sm-block">{product.sku_code}</small>
                                    <small className="text-muted text-left text-truncate d-none d-sm-block">{product.category_name}</small>
                                </div>
                            </div>
                        </>
                    );
                },
            }
        },
        {
            name: '',
            label: "Variation",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <button className="btn btn-sm badge bg-primary" onClick={() => handleAddVariation(productData[tableMeta.rowIndex])}>
                            Variation
                        </button>
                    );
                },
            }
        },

        {
            name: "price",
            label: "Price",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <h6 className="text-body text-nowrap mb-0">₹ {value}</h6>
                    );
                },
            }
        },
        {
            name: "mrp",
            label: "MRP",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <h6 className="text-body text-nowrap mb-0">₹ {value}</h6>
                    );
                },
            }
        },
        {
            name: "best_seller",
            label: "Best Seller",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isChecked = value === 1; // Check if value is 1 for COD
                    return (
                        <>
                            <span className="text-truncate">
                                <label className="switch switch-primary switch-sm">
                                    <input type="checkbox" className="switch-input" id="switch" checked={isChecked} onChange={() => handleBestSellerStatusChange(productData[tableMeta.rowIndex])} />
                                    <span className="switch-toggle-slider">
                                        <span className="switch-off" />
                                    </span>
                                </label>
                            </span>
                        </>
                    );
                },
            }
        },
        {
            name: "featured_products",
            label: "Featured",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isChecked = value === 1; // Check if value is 1 for COD
                    return (
                        <>
                            <span className="text-truncate">
                                <label className="switch switch-primary switch-sm">
                                    <input type="checkbox" className="switch-input" id="switch" checked={isChecked} onChange={() => handleFeaturedStatusChange(productData[tableMeta.rowIndex])} />
                                    <span className="switch-toggle-slider">
                                        <span className="switch-off" />
                                    </span>
                                </label>
                            </span>
                        </>
                    );
                },
            }
        },
        {
            name: "discounted_products",
            label: "Discount",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isChecked = value === 1; // Check if value is 1 for COD
                    return (
                        <>
                            <span className="text-truncate">
                                <label className="switch switch-primary switch-sm">
                                    <input type="checkbox" className="switch-input" id="switch" checked={isChecked} onChange={() => handleDiscountStatusChange(productData[tableMeta.rowIndex])} />
                                    <span className="switch-toggle-slider">
                                        <span className="switch-off" />
                                    </span>
                                </label>
                            </span>
                        </>
                    );
                },
            }
        },
        {
            name: "new_arrival",
            label: "New Arrival",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isChecked = value === 1; // Check if value is 1 for COD
                    return (
                        <>
                            <span className="text-truncate">
                                <label className="switch switch-primary switch-sm">
                                    <input type="checkbox" className="switch-input" id="switch" checked={isChecked} onChange={() => handleNewArrivalStatusChange(productData[tableMeta.rowIndex])} />
                                    <span className="switch-toggle-slider">
                                        <span className="switch-off" />
                                    </span>
                                </label>
                            </span>
                        </>
                    );
                },
            }
        },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <button className={`btn btn-sm badge ${value ? 'bg-success' : 'bg-danger'}`} onClick={() => handleStatusChange(productData[tableMeta.rowIndex])}>
                                {value ? 'Active' : 'Inactive'}
                            </button>
                        </>
                    );
                },
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="d-flex align-items-sm-center justify-content-sm-center">
                                <button className="btn btn-sm btn-icon me-2" onClick={() => handleEditProduct(productData[tableMeta.rowIndex])}>
                                    <i className="ti ti-edit" />
                                </button>
                                <button className="btn btn-sm btn-icon delete-record" onClick={() => handleDeleteProduct(productData[tableMeta.rowIndex])}>
                                    <i className="ti ti-trash" />
                                </button>
                            </div>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'multiselect',    // filtertype for filter icon dropdown
        responsive: 'standard',       // make table responsive
        filter: true,                 // show or hide the filter icon from top right side 
        selectableRows: true         // first column with checkbox
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4 className="py-3 mb-2">
                        <span className="text-muted fw-light">eCommerce /</span> Product List
                    </h4>
                </div>
                <div>
                    <Link to="/add-product" className="btn btn-primary waves-effect waves-light">Add New Product</Link>
                </div>
            </div>
            <div className="app-ecommerce-category">
                <MUIDataTable
                    title={"Product List"}
                    data={productData || []}
                    columns={columns}
                    options={options}
                />
            </div>
        </div>
    )
}

export default ProductList