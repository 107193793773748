import { fetchData } from "../../API/ApiService";
import { API_URLS } from "../../API/apiUrls";
import { toast } from "react-toastify";

// Method to Delete Category.
export const DeleteVariation = async (id) => {
    const requestBody = {
        VariationId: id
    };
    try {
        const response = await fetchData(API_URLS.deleteVariation, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}

// Method to Handle Form Submit.
export const VariationFormSubmit = async (data, mode) => {
    console.log(data);
    try {
        if (mode === "Add") {
            const requestBody = {
                product_id: data.hdnField_productId,
                size_id: data.sizeId,
                color_id: data.colorId,
                mrp: data.productMrp,
                price: data.productPrice,
                qty: data.productQty,
                ImageID: data.imageId
            }
            const response = await fetchData(API_URLS.addVariation, requestBody);
            const responseData = response.data;
            if (response.status === 200) {
                if (responseData.responseCode === 1) {
                    toast.success(responseData.responseMessage);
                }
                else {
                    toast.error(responseData.responseMessage);
                }
            }
        }
        else if (mode === "Edit") {
            const requestBody = {
                variation_id: data.hdnField_varationId,
                size_id: data.sizeId,
                color_id: data.colorId,
                mrp: data.productMrp,
                price: data.productPrice,
                qty: data.productQty,
                ImageID: data.imageId
            }
            const response = await fetchData(API_URLS.updateVariation, requestBody);
            const responseData = response.data;
            if (response.status === 200) {
                if (responseData.responseCode === 1) {
                    toast.success(responseData.responseMessage);
                }
                else {
                    toast.error(responseData.responseMessage);
                }
            }
        }
    }
    catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}