import React, { useState } from 'react'
import InputField from '../Shared/Input/FormField';
import { useForm } from 'react-hook-form';
import { OrderStatusFormSubmit } from './OrderFunction';

const OrderStatusModal = ({ hdnFieldOrderID, isOrderUpdate, setIsOrderUpdate }) => {

    const [selectedOption, setSelectedOption] = useState("");
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
        console.log(e.target.value);
    };

    const handleFormSubmit = async (data) => {
        data.order_status = selectedOption;
        data.order_id = hdnFieldOrderID;
        console.log(data);
        await OrderStatusFormSubmit(data);
        handleReset();
        setIsOrderUpdate(!isOrderUpdate);
    }

    const handleReset = () => {
        reset();
        setSelectedOption("");
        document.getElementById('orderStatusChange').classList.remove('show');
        const modalBackdrop = document.querySelector('.modal-backdrop.fade.show');
        if (modalBackdrop) {
            modalBackdrop.remove();
        }
    }

    return (
        <>
            { /* Add Permission Modal */}
            <div className="modal fade" id="orderStatusChange" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3 p-md-5">
                        <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="text-center mb-4">
                                <h3 className="mb-2">Change Order Status</h3>
                            </div>
                            <form id="OrderStatusForm" className="row" onSubmit={handleSubmit(handleFormSubmit)}>
                                <div className="col-12 mb-3">
                                    <label className="form-label">Status</label>
                                    <select id="order-status" className="form-select" onChange={handleSelectChange} defaultValue="" required>
                                        <option value="" disabled>--select--</option>
                                        <option value="1">Processing</option>
                                        <option value="2">Shipped</option>
                                        <option value="3">Delivered</option>
                                        <option value="5">Cancel</option>
                                    </select>
                                    {errors.order_status && <span className="text-danger">{errors.order_status.message}</span>}
                                </div>

                                {selectedOption === "2" && (
                                    <>
                                        <InputField label="Tracking ID" id="tracking-id" type="text" placeholder="Enter Tracking ID" name="tracking_id" register={register} errors={errors} required />

                                        <InputField label="Courier Name" id="courier-name" type="text" placeholder="Enter Courier Name" name="courier_name" register={register} errors={errors} required />
                                    </>
                                )}

                                {selectedOption === "5" && (
                                    <InputField label="Cancel Reason" id="reason" type="text" placeholder="Enter Cancel Reason" name="reason" register={register} errors={errors} required />
                                )}

                                <div className="col-12 text-center demo-vertical-spacing">
                                    <button type="reset" className="btn btn-label-secondary me-sm-3 me-1" data-bs-dismiss="modal" aria-label="Close">Discard</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            { /*/ Add Permission Modal */}
        </>
    )
}

export default OrderStatusModal
