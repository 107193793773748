import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from "react-toastify";

const OtpVerification = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [userInput, setUserInput] = useState(['', '', '', '', '', '']);
    const Email_id = sessionStorage.getItem("EmailId") || null;
    const OTP = sessionStorage.getItem("OTP") || null;
    const inputRefs = useRef([]);
    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);


    useEffect(() => {
        if (!OTP) {
            navigate("/login");
        }
    }, [navigate, OTP]);


    const handleInputChange = (e, index) => {
        const value = e.target.value;
        setUserInput(prevState => {
            const newState = [...prevState]; // Using spread operator to create a new array
            newState[index] = value;
            return newState;
        });
    }

    const handleVerifyOTP = () => {
        const userEnteredOTP = userInput.join('');
        if (userEnteredOTP === OTP) {
            navigate("/reset-password");
        } else {
            toast.warning("Invalid OTP. Try Again");
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={5}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className="authentication-wrapper authentication-cover authentication-bg">
                <div className="authentication-inner row">

                    { /* /Left Text */}
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img src="/assets/img/auth/auth-two-step.png" alt="auth-two-steps-cover" className="img-fluid my-5 auth-illustration" />
                            <img src="/assets/img/auth/login-bg-shape.png" alt="auth-two-steps-cover" className="platform-bg" />
                        </div>
                    </div>
                    { /* /Left Text */}

                    { /* Two Steps Verification */}
                    <div className="d-flex col-12 col-lg-5 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            { /* Logo */}
                            <div className="app-brand mb-4">
                                <a href="!#" className="app-brand-link gap-2">

                                </a>
                            </div>
                            { /* /Logo */}

                            <h4 className="mb-1">Two Step Verification 💬</h4>
                            <p className="text-start mb-4">
                                We sent a verification code to your mobile. Enter the code from the mobile in the field below.
                                <span className="fw-medium d-block mt-2">{Email_id}</span>
                            </p>
                            <p className="mb-0 fw-medium">Type your 6 digit security code</p>
                            <form id="twoStepsForm" onSubmit={handleSubmit(handleVerifyOTP)}>
                                <div className="mb-3">
                                    <div className="auth-input-wrapper d-flex align-items-center justify-content-sm-between numeral-mask-wrapper">
                                        {[0, 1, 2, 3, 4, 5].map((index) => (
                                            <input
                                                ref={(el) => (inputRefs.current[index] = el)}
                                                key={index}
                                                type="tel"
                                                className="form-control auth-input h-px-50 text-center numeral-mask mx-1 my-2"
                                                maxLength={1}
                                                onChange={(e) => handleInputChange(e, index)}
                                            />
                                        ))}
                                    </div>

                                </div>
                                <button className="btn btn-primary d-grid w-100 mb-3">
                                    Verify my account
                                </button>
                                <div className="text-center">Didn't get the code?
                                    <Link to="/forgot-password">
                                        Resend
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    { /* /Two Steps Verification */}
                </div>
            </div>
        </>
    )
}

export default OtpVerification