import { fetchData } from "../../API/ApiService";
import { API_URLS } from "../../API/apiUrls";
import { toast } from "react-toastify";



// Method to Delete Sub Category.
export const DeleteSubCategory = async (id) => {
    const requestBody = {
        SubCategory_id: id
    };
    try {
        const response = await fetchData(API_URLS.deleteSubCategory, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}

// categoryId , subcategoryTitle , subCategorySlug , categoryStatus , subCategoryId
// handle form submit event.
export const SubCategoryFormSubmit = async (data, mode) => {
    try {
        if (mode === "Add") {
            const requestBody = {
                CategoryID: data.categoryId,
                Name: data.subcategoryTitle,
                Slug: data.subCategorySlug,
                Status: data.categoryStatus
            }
            const response = await fetchData(API_URLS.insertSubCategoryData, requestBody);
            const responseData = response.data;
            if (response.status === 200) {
                if (responseData.responseCode === 1) {
                    toast.success(responseData.responseMessage);
                }
                else {
                    toast.error(responseData.responseMessage);
                }
            }
        }
        else if (mode === "Edit") {
            const requestBody = {
                SubCategory_id: data.subCategoryId,
                Category_id: data.categoryId,
                Name: data.subcategoryTitle,
                Slug: data.subCategorySlug,
                Status: data.categoryStatus
            }
            const response = await fetchData(API_URLS.updateSubCategory, requestBody);
            const responseData = response.data;
            if (response.status === 200) {
                if (responseData.responseCode === 1) {
                    toast.success(responseData.responseMessage);
                }
                else {
                    toast.error(responseData.responseMessage);
                }
            }
        }
    }
    catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
};
