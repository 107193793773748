import { toast } from "react-toastify";
import { API_URLS } from "../../API/apiUrls";
import { fetchData } from "../../API/ApiService";


// Method to Delete Product.
export const DeleteProduct = async (id) => {
    const requestBody = {
        ProductId: id
    };
    try {
        const response = await fetchData(API_URLS.deleteProduct, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}


// Method to Delete Color.
export const DeleteProductImage = async (id) => {
    const requestBody = {
        ID: id
    };
    try {
        const response = await fetchData(API_URLS.deleteProductImage, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}


// Method to Toggle Status of Best Seller, Discounted, New Arrival, Featured.
export const ToggleProductFeatureStatus = async (id, status, columnName) => {
    const requestBody = {
        ID: id,
        Status: status,
        ColumnName: columnName
    };
    try {
        const response = await fetchData(API_URLS.toggleProductFeature, requestBody);
        const responseData = response.data;
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}



// handle form submit event.
export const ProductFormSubmit = async (data, mode) => {    
    console.log(data);
    try 
    {
        const formData = new FormData();
        formData.append('Cat_Id', data.categoryId);
        formData.append('Sub_Cat_Id', data.sub_categoryId);
        formData.append('Name', data.productName);
        formData.append('Slug', data.productSlug);
        formData.append('Short_Description', data.shortDescription);
        formData.append('Description', data.description);
        formData.append('Price', data.productPrice);
        formData.append('Mrp', data.productMrp);
        formData.append('Qty', data.productQty);
        formData.append('SizeId', data.sizeId);
        formData.append('ColorId', data.colorId);
        formData.append('Sku_Code', data.productSKU);
        formData.append('Hsn_Code', data.productHSN);
        formData.append('Product_Weight', data.productWeight);
        formData.append('Shipping_Weight', data.shippingWeight);
        formData.append('Dispatch_Time', data.dispatchTime);
        formData.append('Delivery_Time', data.deliveryTime);
        formData.append('Brand_Id', data.brandId);
        formData.append('Gst', data.gstPercent);
        formData.append('Packed_By', data.packedBy);
        formData.append('Manufactured_By', data.manufactureBy);
        formData.append('Product_Material', data.productMaterial);
        formData.append('Cod', data.iscod);
        formData.append('Best_seller', data.isBestSeller);
        formData.append('Featured_Products', data.isFeatured);
        formData.append('Discounted_Products', data.isDiscounted);
        formData.append('New_Arrival', data.isNewArrival);
        formData.append('Status', data.is_Active);
        formData.append('Video_Link', data.productVideoLink);
        formData.append('Tags', data.tags);

        if (mode === "Add") {
            if (!data.images || data.images.length === 0) {
                toast.warning('Select Image First');
                return;
            }
            data.images.forEach((image, index) => {
                formData.append('Image', image);
            });
        }
        else if (mode === 'Edit') {
            formData.append('id', data.hdnField_productId);

            if (data.images && data.images.length > 0) {
                data.images.forEach((image, index) => {
                    formData.append('Image', image);
                });
            }
        }

        let apiUrl = mode === "Add" ? API_URLS.addProduct : API_URLS.updateProduct;

        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData
        });

        const responseData = await response.json();
        if (response.status === 200) {
            if (responseData.responseCode === 1) {
                toast.success(responseData.responseMessage);
            }
            else {
                toast.error(responseData.responseMessage);
            }
        }

    }
    catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
};
