const API_BASE_URL = "https://zapi.softcron.com/api";
export const CAT_IMAGE_BASE_URL = "https://zapi.softcron.com/category/";
export const PRODUCT_IMAGE_BASE_URL = "https://zapi.softcron.com/Product/";


// const API_BASE_URL = "https://localhost:7008/api";
// export const CAT_IMAGE_BASE_URL = "https://localhost:7008/category/";
// export const PRODUCT_IMAGE_BASE_URL = "https://localhost:7008/Product/";

export const API_URLS = {

    login: `${API_BASE_URL}/AdminSignIn`,
    toggleStatus: `${API_BASE_URL}/ToggleStatus`,
    passwordresetOtp: `${API_BASE_URL}/ForgotAdminPassword`,
    updatePassword: `${API_BASE_URL}/UpdateAdminPassword`,

    // Category api urls
    getActiveCategories: `${API_BASE_URL}/GetAllCategory`,
    getAllCategories: `${API_BASE_URL}/FetchCategory`,
    insertCategoryData: `${API_BASE_URL}/InsertCategory`,
    deleteCategory: `${API_BASE_URL}/DeleteCategory`,
    updateCategory: `${API_BASE_URL}/UpdateCategory`,
    // Sub-category api urls
    fetchActiveSubCategories: `${API_BASE_URL}/FetchActiveSubCategories`,
    getAllSubCategories: `${API_BASE_URL}/FetchSubCategories`,
    insertSubCategoryData: `${API_BASE_URL}/InsertSubCategory`,
    deleteSubCategory: `${API_BASE_URL}/DeleteSubCategory`,
    updateSubCategory: `${API_BASE_URL}/UpdateSubCategory`,
    // color api urls
    getAllActiveColor: `${API_BASE_URL}/GetAllColors`,
    getAllColor: `${API_BASE_URL}/FetchColor`,
    insertNewColor: `${API_BASE_URL}/AddNewColor`,
    updateColor: `${API_BASE_URL}/UpdateColor`,
    deleteColor: `${API_BASE_URL}/DeleteColor`,
    // size api urls
    getAllActiveSize: `${API_BASE_URL}/GetAllSizes`,
    getAllSize: `${API_BASE_URL}/FetchSize`,
    insertNewSize: `${API_BASE_URL}/AddNewSize`,
    updateSize: `${API_BASE_URL}/UpdateSize`,
    deleteSize: `${API_BASE_URL}/DeleteSize`,
    // product
    addProduct: `${API_BASE_URL}/InsertProduct`,
    updateProduct: `${API_BASE_URL}/UpdateProduct`,
    fetchProducts: `${API_BASE_URL}/FetchProduct`,
    fetchProductImageById: `${API_BASE_URL}/FetchProductImageById`,
    deleteProduct: `${API_BASE_URL}/DeleteProduct`,
    deleteProductImage: `${API_BASE_URL}/DeleteProductImage`,
    toggleProductFeature: `${API_BASE_URL}/UpdateSingleToggle`,
    // Brand API
    addBrand: `${API_BASE_URL}/InsertBrand`,
    updateBrand: `${API_BASE_URL}/UpdateBrand`,
    deleteBrand: `${API_BASE_URL}/DeleteBrand`,
    fetchBrand: `${API_BASE_URL}/FetchBrand`,
    fetchBrandForDropDown: `${API_BASE_URL}/FetchActiveBrand`,
    // Variation 
    addVariation: `${API_BASE_URL}/InsertVariation`,
    deleteVariation: `${API_BASE_URL}/DeleteVariation`,
    getVariation: `${API_BASE_URL}/FetchVariation`,
    updateVariation: `${API_BASE_URL}/UpdateVariation`,
    // Order
    getOrders: `${API_BASE_URL}/FetchOrder`,
    updateOrderStatus: `${API_BASE_URL}/UpdateOrder`,
    getOrderDetail: `${API_BASE_URL}/FetchOrderDetailByID`,
};
