import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify';
import '../../../src/assets/css/dropzone.css';


const DropZone = ({ files, setFiles }) => {

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles?.length) {
            const totalFiles = [...files, ...acceptedFiles];
            if (totalFiles.length > 5) {
                toast.warning("You can only upload up to 5 images.");
            }
            else {
                const imageFiles = acceptedFiles.filter(file => {
                    const extension = file.name.split('.').pop().toLowerCase();
                    const supportedExtensions = ['png', 'jpg', 'jpeg', 'webp'];
                    const isValidExtension = supportedExtensions.includes(extension);
                    if (!isValidExtension) {
                        toast.warning("Only png, jpg, jpeg and webp files are allowded;");
                    }
                    return isValidExtension && file.type.startsWith('image/');
                });

                setFiles(prevFiles => [...prevFiles, ...imageFiles]);
            }
        }
    }, [files, setFiles]);


    const removeFile = indexToRemove => {
        setFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <>
            <div {...getRootProps()} className="dropzone needsclick dz-clickable">
                <input {...getInputProps()} />
                {
                    isDragActive ? (
                        <p className='text-center'>Drop the files here ...</p>
                    ) : (
                        <div className='dz-message needsclick'>
                            Drop files here or click to upload
                        </div>
                    )}
            </div>


            <div className='mt-4'>
                {files.map((file, index) => (
                    <>
                        <div className="d-inline-block">
                            <div className="dz-preview" key={file.name}>
                                <div className="dz-details">
                                    <div className="dz-thumbnail">
                                        <img src={URL.createObjectURL(file)} alt="img" />
                                    </div>
                                    <div className="dz-filename">
                                        {file.path}
                                    </div>
                                    <div className="dz-size">
                                        <strong>7.6 Kb</strong>
                                    </div>
                                    <a type="button" className='dz-remove errorMsg' onClick={() => removeFile(index)}>Remove File</a>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </>
    )
}

export default DropZone


