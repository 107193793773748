import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { UpdatePassword } from './AuthFunction';
import { ToastContainer, Bounce } from "react-toastify";

const ResetPassword = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const newPassword = watch("newPassword", "");
    const emailId = sessionStorage.getItem("EmailId") || null;

    useEffect(() => {
        if (!emailId) {
            navigate("/login");
        }
    }, [navigate, emailId]);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handlePasswordChange = async (data) => {
        const newPassword = data.newPassword;
        await UpdatePassword(emailId, newPassword);
        navigate("/login");
    }

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("EmailID");
            sessionStorage.removeItem("OTP");
        };
    }, []);




    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={5}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className="authentication-wrapper authentication-cover authentication-bg ">
                <div className="authentication-inner row">

                    { /* /Left Text */}
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img src="/assets/img/auth/login-img.png" alt="auth-reset-password-cover" className="img-fluid my-5 auth-illustration" />
                            <img src="/assets/img/auth/login-bg-shape.png" alt="auth-reset-password-cover" className="platform-bg" />
                        </div>
                    </div>
                    { /* /Left Text */}

                    { /* Reset Password */}
                    <div className="d-flex col-12 col-lg-5 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            { /* Logo */}
                            <div className="app-brand mb-4">
                                <a href="!#" className="app-brand-link gap-2">
                                </a>
                            </div>
                            { /* /Logo */}
                            <h4 className="mb-1">Reset Password 🔒</h4>
                            <p className="mb-4">for <span className="fw-medium">{emailId}</span></p>
                            <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(handlePasswordChange)}>

                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label">New Password</label>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input type={showNewPassword ? "text" : "password"} id="new-password" className="form-control" name="new-password" placeholder="*******" aria-describedby="password" {...register("newPassword", {
                                            required: "Password is required"
                                        })} />
                                        <span className="input-group-text cursor-pointer" onClick={toggleNewPasswordVisibility}><i className={showNewPassword ? "ti ti-eye" : "ti ti-eye-off"} /></span>
                                    </div>
                                    {errors.newPassword && <p className="errorMsg">{errors.newPassword.message}</p>}
                                </div>

                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label">Confirm Password</label>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input type={showConfirmPassword ? "text" : "password"} id="confirm-password" className="form-control" name="confirm-password" placeholder="*******" aria-describedby="password" {...register("confirmPassword", {
                                            required: "Please confirm your password",
                                            validate: value => value === newPassword || "Passwords do not match"
                                        })} />
                                        <span className="input-group-text cursor-pointer" onClick={toggleConfirmPasswordVisibility}><i className={showConfirmPassword ? "ti ti-eye" : "ti ti-eye-off"} /></span>
                                    </div>
                                    {errors.confirmPassword && <p className="errorMsg">{errors.confirmPassword.message}</p>}
                                </div>

                                <button type='Submit' className="btn btn-primary d-grid w-100 mb-3">
                                    Set new password
                                </button>
                                <div className="text-center">
                                    <Link to="/login">
                                        <i className="ti ti-chevron-left scaleX-n1-rtl" />
                                        Back to login
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    { /* /Reset Password */}
                </div>
            </div>
        </>
    )
}

export default ResetPassword