import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../API/ApiService';
import { API_URLS } from '../../../API/apiUrls';
import { toast } from 'react-toastify';

const BrandDropDown = ({ register, errors, required }) => {

    const [brandData, setBrandData] = useState([]);

    useEffect(() => {
        const fetchBrandData = async () => {
            try {
                const response = await fetchData(API_URLS.fetchBrandForDropDown, {});
                const responseData = response.data;
                if (response.status === 200) {
                    if (responseData.responseCode === 1) {
                        const data = JSON.parse(responseData.responseData);
                        setBrandData(data);
                    }
                    else {
                        toast.error(responseData.responseMessage);
                    }
                }
            }
            catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchBrandData();
    }, []);

    return (
        <>
            { /* Brand DropDown */}
            <div className="mb-3">
                <label className="form-label">Select Brand</label>
                <select id="brand-dropdown" className="form-select" {...register("brandId", {
                    ...(required && { required: `Please select a value` }),
                })} defaultValue="">
                    {brandData && brandData.length > 0 ? (
                        <>
                            <option value="" disabled>--Select--</option>
                            {brandData.map(brand => (
                                <option key={brand.id} value={brand.id}>{brand.brand_name}</option>
                            ))}
                        </>
                    ) : (
                        <option value="" disabled>-- No Brands Available --</option>
                    )}
                </select>
                {errors.brandId && <p className='errorMsg'>{errors.brandId.message}</p>}
            </div>

        </>
    )
}

export default BrandDropDown