import React from 'react'
import { PRODUCT_IMAGE_BASE_URL } from '../../API/apiUrls'

const OrderItem = ({ orderItem, index }) => {
    return (
        <tr className="odd">
            <td className="control">
                {index + 1}.
            </td>
            <td className="sorting_1">
                <div className="d-flex justify-content-start align-items-center text-normal">
                    <div className="avatar-wrapper">
                        <div className="avatar-2 me-2">
                            <img src={`${PRODUCT_IMAGE_BASE_URL}${orderItem.image_name}`} alt="product-Wooden Chair" className="rounded-2" />
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <h6 className="text-body mb-0">{orderItem.product_name}</h6>
                        <small className="text-muted">{orderItem.sku_code}</small>
                        <small className="text-muted">{orderItem.color} | {orderItem.size}</small>
                        <small className="text-muted">{orderItem.category_name}</small>
                    </div>
                </div>
            </td>
            <td className='text-center'>
                <span>₹ {orderItem.price.toFixed(2)}</span>
            </td>
            <td className='text-center'>
                <span className="text-body">{orderItem.qty}</span>
            </td>
            <td>
                <h6 className="mb-0 text-end">₹ {(orderItem.price * orderItem.qty).toFixed(2)}</h6>
            </td>
        </tr>
    )
}

export default OrderItem