import React, { useEffect, useState } from 'react'
import SubCategoryModal from './SubCategoryModal'
import { fetchData } from '../../API/ApiService';
import { API_URLS } from '../../API/apiUrls';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import MUIDataTable from "mui-datatables";
import { ToggleActiveDeactivateStatus } from '../../API/CommonFunction';
import { DeleteSubCategory } from './SubCategoryFunction';

const SubCategoryList = () => {

    const [subCategoryData, setSubCategoryData] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [isSubCategoryUpdate, setisSubCategoryUpdate] = useState(false);

    useEffect(() => {
        const fetchSubCategories = async () => {
            try {
                const response = await fetchData(API_URLS.getAllSubCategories, {});
                const data = response.data;
                if (response.status === 200) {
                    if (data.responseCode === 1) {
                        const responseData = JSON.parse(data.responseData);
                        setSubCategoryData(responseData);
                    }
                    else {
                        toast.error(data.responseMessage);
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchSubCategories();
    }, [isSubCategoryUpdate]);


    const handleEditCategory = (rowData) => {
        setSelectedSubCategory(rowData);
        // Open the offcanvas modal for editing
        const offcanvasBackdrop = document.createElement('div');
        offcanvasBackdrop.className = 'offcanvas-backdrop fade show';
        // Append the element to the document body
        document.body.appendChild(offcanvasBackdrop);
        document.getElementById('offcanvasSubCategory').classList.add('show');
    };

    const handleDeleteCategory = async (rowData) => {
        const sub_cat_id = rowData.id;
        await DeleteSubCategory(sub_cat_id);
        setisSubCategoryUpdate(!isSubCategoryUpdate);
    };

    const handleStatusChange = async (rowData) => {
        const sub_cat_id = rowData.id;
        const tableName = "sub_category";
        const status = rowData.is_active === 1 ? 0 : 1;
        await ToggleActiveDeactivateStatus(sub_cat_id, status, tableName);
        setisSubCategoryUpdate(!isSubCategoryUpdate);
    }


    const columns = [
        {
            name: '',
            label: 'Sr. No.',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, update) => {
                    let rowIndex = Number(tableMeta.rowIndex) + 1;
                    return (<span>{rowIndex}</span>)
                }
            },
        },
        { name: "category_name", label: "Category Name" },
        { name: "sub_category_name", label: "SubCategory Name" },
        { name: "slug", label: "Slug" },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <button className={`btn btn-sm badge ${value ? 'bg-success' : 'bg-danger'}`} onClick={() => handleStatusChange(subCategoryData[tableMeta.rowIndex])}>
                                {value ? 'Active' : 'Inactive'}
                            </button>
                        </>
                    );
                },
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="d-flex align-items-sm-center justify-content-sm-center">
                                <button className="btn btn-sm btn-icon me-2" onClick={() => handleEditCategory(subCategoryData[tableMeta.rowIndex])}>
                                    <i className="ti ti-edit" />
                                </button>
                                <button className="btn btn-sm btn-icon delete-record" onClick={() => handleDeleteCategory(subCategoryData[tableMeta.rowIndex])}>
                                    <i className="ti ti-trash" />
                                </button>
                            </div>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'multiselect',    // filtertype for filter icon dropdown
        responsive: 'standard',       // make table responsive
        filter: true,                 // show or hide the filter icon from top right side 
        selectableRows: false         // first column with checkbox
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h4 className="py-3 mb-2">
                            <span className="text-muted fw-light">eCommerce /</span> Sub Category List
                        </h4>
                    </div>
                    <div>
                        <button className="btn btn-secondary add-new btn-primary ms-2 waves-effect waves-light" aria-controls="offcanvasSubCategory" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSubCategory">
                            <span><i className="ti ti-plus ti-xs me-0 me-sm-2" />
                                <span className="d-none d-sm-inline-block">Add Sub Category</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-ecommerce-category">
                    <MUIDataTable
                        title={"Sub-Category List"}
                        data={subCategoryData || []}
                        columns={columns}
                        options={options}
                    />
                    <SubCategoryModal selectedSubCategory={selectedSubCategory} setSelectedSubCategory={setSelectedSubCategory} isSubCategoryUpdate={isSubCategoryUpdate} setisSubCategoryUpdate={setisSubCategoryUpdate} />
                </div>
            </div>
        </>
    )
}

export default SubCategoryList