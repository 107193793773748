import React, { useEffect, useState } from 'react';
import { fetchData } from '../../API/ApiService';
import { API_URLS } from '../../API/apiUrls';
import ColorModal from './ColorModal';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import { ToggleActiveDeactivateStatus } from '../../API/CommonFunction';
import { DeleteColor } from './ColorFunction';

const ColorList = () => {

    const [colorData, setColorData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isColorUpdate, setIsColorUpdate] = useState(false);

    useEffect(() => {
        const fetchColorData = async () => {
            try {
                const response = await fetchData(API_URLS.getAllColor, {});
                const data = response.data;
                if (response.status === 200) {
                    if (data.responseCode === 1) {
                        const responseData = JSON.parse(data.responseData);
                        setColorData(responseData);
                    }
                    else {
                        toast.error(data.responseMessage);
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchColorData();
    }, [isColorUpdate]);


    const handleEditColor = (rowData) => {
        setSelectedRowData(rowData);
        // Open the offcanvas modal for editing
        const offcanvasBackdrop = document.createElement('div');
        offcanvasBackdrop.className = 'offcanvas-backdrop fade show';
        // Append the element to the document body
        document.body.appendChild(offcanvasBackdrop);
        document.getElementById('offcanvasColor').classList.add('show');
    };

    const handleDeleteColor = async (rowData) => {
        const color_id = rowData.id;
        await DeleteColor(color_id);
        setIsColorUpdate(!isColorUpdate);
    };


    const handleStatusChange = async (rowData) => {
        const color_id = rowData.id;
        const tableName = "color";
        const status = rowData.is_active === 1 ? 0 : 1;
        await ToggleActiveDeactivateStatus(color_id, status, tableName);
        setIsColorUpdate(!isColorUpdate);
    }


    const columns = [
        {
            name: '',
            label: 'Sr. No.',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, update) => {
                    let rowIndex = Number(tableMeta.rowIndex) + 1;
                    return (<span>{rowIndex}</span>)
                }
            },
        },
        { name: "color", label: "Color Name" },
        { name: "colorCode", label: "Color Code" },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <button className={`btn btn-sm badge ${value ? 'bg-success' : 'bg-danger'}`} onClick={() => handleStatusChange(colorData[tableMeta.rowIndex])}>
                                {value ? 'Active' : 'Inactive'}
                            </button>
                        </>
                    );
                },
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="d-flex align-items-sm-center justify-content-sm-center">
                                <button className="btn btn-sm btn-icon me-2" onClick={() => handleEditColor(colorData[tableMeta.rowIndex])}>
                                    <i className="ti ti-edit" />
                                </button>
                                <button className="btn btn-sm btn-icon delete-record" onClick={() => handleDeleteColor(colorData[tableMeta.rowIndex])}>
                                    <i className="ti ti-trash" />
                                </button>
                            </div>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'multiselect',    // filtertype for filter icon dropdown
        responsive: 'standard',       // make table responsive
        filter: true,                 // show or hide the filter icon from top right side 
        selectableRows: false         // first column with checkbox
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h4 className="py-3 mb-2">
                            <span className="text-muted fw-light">eCommerce /</span> Variation Color List
                        </h4>
                    </div>
                    <div>
                        <button className="btn btn-secondary add-new btn-primary ms-2 waves-effect waves-light" aria-controls="offcanvasColor" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasColor">
                            <span><i className="ti ti-plus ti-xs me-0 me-sm-2" />
                                <span className="d-none d-sm-inline-block">Add New Color</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-ecommerce-category">
                    <MUIDataTable
                        title={"Color List"}
                        data={colorData || []}
                        columns={columns}
                        options={options}
                    />
                    <ColorModal selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} isColorUpdate={isColorUpdate} setIsColorUpdate={setIsColorUpdate} />
                </div>
            </div>
        </>
    )
}

export default ColorList