import React, { useEffect, useState } from 'react'
import { fetchData } from '../../API/ApiService';
import { API_URLS } from '../../API/apiUrls';
import { toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import { ToggleActiveDeactivateStatus } from '../../API/CommonFunction';
import { DeleteSize } from './SizeFunction';
import SizeModal from './SizeModal';

const SizeList = () => {

    const [sizeData, setSizeData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isSizeUpdate, setIsSizeUpdate] = useState(false);

    useEffect(() => {
        const fetchSizeData = async () => {
            try {
                const response = await fetchData(API_URLS.getAllSize, {});
                const data = response.data;
                if (response.status === 200) {
                    if (data.responseCode === 1) {
                        const responseData = JSON.parse(data.responseData);
                        setSizeData(responseData);
                    }
                    else {
                        toast.error(data.responseMessage);
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {          // Handle 401 error                    
                    alert("User not authenticated. Please login first.");
                    window.location.href = "/login";
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            }
        }
        fetchSizeData();
    }, [isSizeUpdate]);


    const handleEditSize = (rowData) => {
        setSelectedRowData(rowData);
        // Open the offcanvas modal for editing
        const offcanvasBackdrop = document.createElement('div');
        offcanvasBackdrop.className = 'offcanvas-backdrop fade show';
        // Append the element to the document body
        document.body.appendChild(offcanvasBackdrop);
        document.getElementById('offcanvasSize').classList.add('show');
    };

    const handleDeleteSize = async (rowData) => {
        const size_id = rowData.id;
        await DeleteSize(size_id);
        setIsSizeUpdate(!isSizeUpdate);
    };

    const handleStatusChange = async (rowData) => {
        const size_id = rowData.id;
        const tableName = "size";
        const status = rowData.is_active === 1 ? 0 : 1;
        await ToggleActiveDeactivateStatus(size_id, status, tableName);
        setIsSizeUpdate(!isSizeUpdate);
    }

    const columns = [
        {
            name: '',
            label: 'Sr. No.',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, update) => {
                    let rowIndex = Number(tableMeta.rowIndex) + 1;
                    return (<span>{rowIndex}</span>)
                }
            },
        },
        { name: "size", label: "Size Name" },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <button className={`btn btn-sm badge ${value ? 'bg-success' : 'bg-danger'}`} onClick={() => handleStatusChange(sizeData[tableMeta.rowIndex])}>
                                {value ? 'Active' : 'Inactive'}
                            </button>
                        </>
                    );
                },
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="d-flex align-items-sm-center justify-content-sm-center">
                                <button className="btn btn-sm btn-icon me-2" onClick={() => handleEditSize(sizeData[tableMeta.rowIndex])}>
                                    <i className="ti ti-edit" />
                                </button>
                                <button className="btn btn-sm btn-icon delete-record" onClick={() => handleDeleteSize(sizeData[tableMeta.rowIndex])}>
                                    <i className="ti ti-trash" />
                                </button>
                            </div>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'multiselect',    // filtertype for filter icon dropdown
        responsive: 'standard',       // make table responsive
        filter: true,                 // show or hide the filter icon from top right side 
        selectableRows: false         // first column with checkbox
    };


    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4 className="py-3 mb-2">
                        <span className="text-muted fw-light">eCommerce /</span> Variation Size List
                    </h4>
                </div>
                <div>
                    <button className="btn btn-secondary add-new btn-primary ms-2 waves-effect waves-light" aria-controls="offcanvasSize" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSize">
                        <span><i className="ti ti-plus ti-xs me-0 me-sm-2" />
                            <span className="d-none d-sm-inline-block">Add New Size</span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="app-ecommerce-category">
                <MUIDataTable
                    title={"Size List"}
                    data={sizeData || []}
                    columns={columns}
                    options={options}
                />
                <SizeModal selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} isSizeUpdate={isSizeUpdate} setIsSizeUpdate={setIsSizeUpdate} />
            </div>
        </div>
    </>
    )
}

export default SizeList