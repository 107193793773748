import React from 'react';

const InputField = ({ label, id, type, placeholder, name, register, errors, required, videolink }) => {
    return (
        <>
            <div className="mb-3">
                <label className="form-label" htmlFor={id}>{label}</label>
                <input
                    type={type}
                    id={id}
                    className="form-control"
                    placeholder={placeholder}
                    aria-label={label}
                    name={name}
                    {...register(name, {
                        ...(required && { required: `${label} is required.` }),
                        ...(videolink && { pattern: {
                            value: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
                            message: "Please enter a valid YouTube video link."
                        }})
                    })}
                />
                {errors && errors[name] && <p className="errorMsg">{errors[name].message}</p>}
            </div>
        </>
    );
};

export default InputField
