import { toast } from "react-toastify";
import { fetchData } from "../../API/ApiService";
import { API_URLS } from "../../API/apiUrls";


// Function to Send OTP to user Email Address
export const SendResetPasswordOTP = async (email_id, navigate) => {    

    const requestBody = {
        EmailId: email_id
    };

    try {
        const response = await fetchData(API_URLS.passwordresetOtp, requestBody);
        const data = response.data;
        if (data.responseCode === 1) {
            const responseData = JSON.parse(data.responseData);
            const otpMatch = responseData.match(/\d+/);
            if (otpMatch) {
                const OTP = otpMatch[0];
                sessionStorage.setItem("EmailId", email_id);
                sessionStorage.setItem("OTP", OTP);
                navigate("/otp-verification");
            }
            else {
                toast.error("Invalid OTP format");
            }
        } else {
            toast.error(data.responseMessage);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(error.message);
        }
    }
}


// Function to Send OTP to user Email Address
export const UpdatePassword = async (email_id, password) => {

    const requestBody = {
        EmailId: email_id,
        Password: password
    };

    try {
        const response = await fetchData(API_URLS.updatePassword, requestBody);
        const data = response.data;
        if (data.responseCode === 1) {
            toast.success(data.responseMessage);
        } else {
            toast.error(data.responseMessage);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {          // Handle 401 error                    
            alert("User not authenticated. Please login first.");
            window.location.href = "/login";
        } else {
            toast.error(`Error: ${error.message}`);
        }
    }
}


