import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { fetchData } from '../../API/ApiService';
import OrderStatusBadge from './OrderStatusBadge';
import OrderDetailTable from './OrderDetailTable';
import { API_URLS } from '../../API/apiUrls';
import CustomerDetailCard from './CustomerDetailCard';

const OrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [orderDetail, setOrderDetail] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const order_id = queryParams.get('id');

        if (order_id) {
            const fetchOrderDetail = async () => {
                try {
                    const requestBody = {
                        OrderId: order_id
                    };

                    const response = await fetchData(API_URLS.getOrderDetail, requestBody);
                    const data = response.data;
                    if (response.status === 200) {
                        if (data.responseCode === 1) {
                            const responseData = JSON.parse(data.responseData);
                            setOrderDetail(responseData);
                        }
                        else {
                            toast.error(`Error: ${data.responseMessage}`);
                        }
                    }
                } catch (error) {
                    if (error.response && error.response.status === 401) {          // Handle 401 error
                        alert("User not authenticated. Please login first.");
                        window.location.href = "/login";
                    } else {
                        toast.error(`Error: ${error.message}`);
                    }
                }
            }
            fetchOrderDetail();
        }
        else {
            navigate('/order-list');
        }
    }, [location.search, navigate]);



    return (
        <>
            {orderDetail && (
                <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="py-3 mb-2">
                        <span className="text-muted fw-light">eCommerce /</span> Order Details
                    </h4>

                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3">
                        <div className="d-flex flex-column justify-content-center gap-2 gap-sm-0">
                            <h5 className="mb-1 mt-3 d-flex flex-wrap gap-2 align-items-end">Order #{orderDetail[0].order_id}
                                {orderDetail[0].payment_id === "cod" && orderDetail[0].payment_id !== 0 ? (
                                    <span className="badge bg-label-warning">COD</span>
                                ) : (
                                    <span className="badge bg-label-success">Paid</span>
                                )}
                                {/* Bind Order Status Badge based on Order_status */}
                                <OrderStatusBadge orderStatus={orderDetail[0].order_status} />

                                {orderDetail[0].order_status === 4 || orderDetail[0].order_status === 5 ? (
                                    <span>{orderDetail[0].order_cancel_reason}</span>
                                ) : null}

                            </h5>
                            <p className="text-body">
                                <div className='mb-2'>
                                    {orderDetail[0].payment_id === "cod" && (
                                        <>Payment Id : {orderDetail[0].payment_id}</>
                                    )}
                                </div>
                                {new Date(orderDetail[0].created_at).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })}
                                {' | '}
                                {new Date(orderDetail[0].created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </p>
                        </div>
                        <div className="d-flex align-content-center flex-wrap gap-2">
                            <button className="btn btn-label-danger delete-order">Invoice</button>
                        </div>
                    </div>

                    {/* Order Detail Table */}
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <OrderDetailTable orderDetail={orderDetail} />
                        </div>
                        <div className="col-12 col-lg-4">
                            <CustomerDetailCard orderDetail={orderDetail} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default OrderDetail