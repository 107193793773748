import React from 'react'

const EmailInput = ({register, errors}) => {
    return (
        <>
            <div className="mb-3">
                <label htmlFor="email-id" className="form-label">Email</label>
                <input type="text" className="form-control" id="email-id" name="email" placeholder="Enter your email" autoFocus {...register("email", {
                    required: "Email is Required",
                    pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Enter valid Email."
                    }
                })} />
                {errors.email && <p className="errorMsg">{errors.email.message}</p>}
            </div>
        </>
    )
}

export default EmailInput