import React, { useEffect, useState } from 'react'
import { fetchData } from '../../API/ApiService';
import { API_URLS, PRODUCT_IMAGE_BASE_URL } from '../../API/apiUrls';
import { toast } from "react-toastify";
import { ToggleActiveDeactivateStatus } from '../../API/CommonFunction';
import MUIDataTable from "mui-datatables";
import { DeleteVariation } from './VariationFunction';

const VariationList = ({ selectedRowData, setSelectedRowData, isVariationUpdate, setisVariationUpdate }) => {

    const [variation, setVariation] = useState([]);
    const product_id = sessionStorage.getItem("product_Id") || null;

    useEffect(() => {
        if (product_id !== null) {
            const fetchVariation = async () => {
                const requestBody = {
                    ProductId: product_id
                };
                try {
                    const response = await fetchData(API_URLS.getVariation, requestBody);
                    const data = response.data;
                    if (response.status === 200) {
                        if (data.responseCode === 1) {
                            const responseData = JSON.parse(data.responseData);
                            setVariation(responseData || []);
                        }
                        else {
                            toast.error(`Error: ${data.responseMessage}`);
                        }
                    }
                }
                catch (error) {
                    if (error.response && error.response.status === 401) {          // Handle 401 error                    
                        alert("User not authenticated. Please login first.");
                        window.location.href = "/login";
                    } else {
                        toast.error(`Error: ${error.message}`);
                    }
                }
            }
            fetchVariation();
        }
    }, [isVariationUpdate, product_id]);


    const handleEditVariation = (rowData) => {
        setSelectedRowData(rowData);

    };

    const handleDeleteVariation = async (rowData) => {
        const variation_id = rowData.id;
        await DeleteVariation(variation_id);
        setisVariationUpdate(!isVariationUpdate);
    };


    const handleStatusChange = async (rowData) => {
        const variation_id = rowData.id;
        const tableName = "variation";
        const status = rowData.is_active === 1 ? 0 : 1;
        await ToggleActiveDeactivateStatus(variation_id, status, tableName);
        setisVariationUpdate(!isVariationUpdate);
    }

    const columns = [
        {
            name: '',
            label: 'Sr. No.',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, update) => {
                    let rowIndex = Number(tableMeta.rowIndex) + 1;
                    return (<span>{rowIndex}</span>)
                }
            },
        },
        {
            name: "image_name",
            label: "Image",
            options: {
                setCellProps: () => ({
                    style: {
                        textAlign: 'center',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div className="avtar-wrapper rounded-2 bg-label-secondary">
                                    <div className="avatar">
                                        <img src={`${PRODUCT_IMAGE_BASE_URL}${value}`} alt="" className="rounded-2" />
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                },
            }
        },
        { name: "color", label: "Color" },
        { name: "size", label: "Size" },
        { name: "price", label: "Price" },
        { name: "mrp", label: "MRP" },
        { name: "qty", label: "Quantity" },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <button className={`btn btn-sm badge ${value ? 'bg-success' : 'bg-danger'}`} onClick={() => handleStatusChange(variation[tableMeta.rowIndex])}>
                                {value ? 'Active' : 'Inactive'}
                            </button>
                        </>
                    );
                },
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="d-flex align-items-sm-center justify-content-sm-center">
                                <button className="btn btn-sm btn-icon me-2" onClick={() => handleEditVariation(variation[tableMeta.rowIndex])}>
                                    <i className="ti ti-edit" />
                                </button>
                                <button className="btn btn-sm btn-icon delete-record" onClick={() => handleDeleteVariation(variation[tableMeta.rowIndex])}>
                                    <i className="ti ti-trash" />
                                </button>
                            </div>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'multiselect',    // filtertype for filter icon dropdown
        responsive: 'standard',       // make table responsive
        filter: true,                 // show or hide the filter icon from top right side 
        selectableRows: false         // first column with checkbox
    };



    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="app-ecommerce-category">
                    <MUIDataTable
                        title={"Variation List"}
                        data={variation || []}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </>
    )
}

export default VariationList