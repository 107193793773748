import React from 'react'
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("admin_id") !== null; // Or use sessionStorage if that's your preference

  if (!isAuthenticated) {
    navigate("/login"); 
    return null; 
  }

  return (
    <h1 className='text-center mt-5'>Welcome To DashBoard</h1>
  )
}

export default Dashboard