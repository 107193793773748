import React from 'react'

const CustomerDetailCard = ({ orderDetail }) => {
    return (
        <>
            <div className="card mb-4">
                <div className="card-header">
                    <h6 className="card-title m-0">Customer details</h6>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-start align-items-center mb-4">
                        <div className="avatar me-2">
                            <img src="/assets/img/user.png" alt="Avatar" className="rounded-circle" />
                        </div>
                        <div className="d-flex flex-column">
                            <a href="app-user-view-account.html" className="text-body text-nowrap">
                                <h6 className="mb-0">{orderDetail[0].user_name}</h6>
                            </a>
                            <small className="text-muted">Customer ID: {orderDetail[0].user_id}</small></div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <h6>Contact info</h6>
                    </div>
                    <p className=" mb-1">Email: {orderDetail[0].user_email}</p>
                    <p className=" mb-0">Mobile: {orderDetail[0].user_phone}</p>
                </div>
            </div>

            <div className="card mb-4">

                <div className="card-header d-flex justify-content-between">
                    <h6 className="card-title m-0">Shipping address</h6>
                </div>
                <div className="card-body">
                    <p className="mb-0">{orderDetail[0].address_name} <br />
                        {orderDetail[0].house}, {orderDetail[0].area} <br />
                        {orderDetail[0].landmark}, {orderDetail[0].city} <br />
                        {orderDetail[0].state}, {orderDetail[0].country} - {orderDetail[0].pincode}</p>
                </div>
            </div>
        </>
    )
}

export default CustomerDetailCard