import React from 'react'

const OrderStatusBadge = ({ orderStatus }) => {

    let badgeClass = '';
    let statusText = '';

    switch (orderStatus) {
        case 0:
            badgeClass = 'bg-label-info';
            statusText = 'Payment Failed';
            break;
        case 1:
            badgeClass = 'bg-label-warning';
            statusText = 'Processing';
            break;
        case 2:
            badgeClass = 'bg-label-primary';
            statusText = 'Dispatched';
            break;
        case 3:
            badgeClass = 'bg-label-success';
            statusText = 'Delivered';
            break;
        case 4:
            badgeClass = 'bg-label-danger';
            statusText = 'Canceled By User';
            break;
        case 5:
            badgeClass = 'bg-label-danger';
            statusText = 'Canceled By Admin';
            break;
        default:
            break;
    }

    return (
        <span className={`badge px-2 ${badgeClass}`}>{statusText}</span>
    )
}

export default OrderStatusBadge