import React from 'react'
import EmailInput from '../Shared/Input/EmailInput'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { SendResetPasswordOTP } from './AuthFunction';
import { ToastContainer, Bounce } from "react-toastify";

const ForgotPassword = () => {

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleForgotPassword = async (data) => {
        console.log(data);
        const email_id = data.email;
        SendResetPasswordOTP(email_id, navigate);
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={5}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className="authentication-wrapper authentication-cover authentication-bg">
                <div className="authentication-inner row">

                    { /* /Left Text */}
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img src="/assets/img/auth/forgot-password.png" alt="auth-forgot-password-cover" className="img-fluid my-5 auth-illustration" />

                            <img src="/assets/img/auth/login-bg-shape.png" alt="auth-forgot-password-cover" className="platform-bg" />
                        </div>
                    </div>
                    { /* /Left Text */}

                    { /* Forgot Password */}
                    <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            { /* Logo */}
                            <div className="app-brand mb-4">
                                <a href="index.html" className="app-brand-link gap-2">

                                </a>
                            </div>
                            { /* /Logo */}
                            <h3 className="mb-1">Forgot Password? 🔒</h3>
                            <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p>
                            <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(handleForgotPassword)}>
                                <EmailInput register={register} errors={errors} />
                                <button type='Submit' className="btn btn-primary d-grid w-100">Send Reset OTP</button>
                            </form>
                            <div className="text-center">
                                <Link to='/login' className="d-flex align-items-center justify-content-center">
                                    <i className="ti ti-chevron-left scaleX-n1-rtl" />
                                    Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                    { /* /Forgot Password */}
                </div>
            </div>
        </>
    )
}

export default ForgotPassword