import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../Shared/Input/FormField';
import StatusDropdown from '../Shared/Input/StatusDropdown';
import { SizeFormSubmit } from './SizeFunction';

const SizeModal = ({ selectedRowData, setSelectedRowData, isSizeUpdate, setIsSizeUpdate }) => {

    const [mode, setMode] = useState("Add");
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        if (selectedRowData) {
            setMode("Edit");
            reset({
                hdnField_sizeId: selectedRowData.id,
                sizeName: selectedRowData.size,
                sizeStatus: selectedRowData.is_active.toString()
            });
        }
        else {
            setMode("Add");
            reset({
                hdnField_sizeId: "",
                sizeName: "",
                sizeStatus: ""
            });
        }
    }, [selectedRowData, reset]);

    const handleFormSubmit = async (data) => {
        console.log(data);
        await SizeFormSubmit(data, mode);
        handleReset();
        setIsSizeUpdate(!isSizeUpdate);
    }

    const handleReset = () => {
        reset();
        setMode("Add");
        setSelectedRowData(null);
        document.getElementById('offcanvasSize').classList.remove('show');
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop.fade.show');
        if (offcanvasBackdrop) {
            offcanvasBackdrop.remove();
        }
    };

    return (
        <>
            { /* Offcanvas to add new customer */}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasSize" aria-labelledby="offcanvasSizeLabel" data-bs-backdrop="static" data-bs-scroll="true" >
                { /* Offcanvas Header */}
                <div className="offcanvas-header py-4">
                    <h5 id="offcanvasSizeLabel" className="offcanvas-title">{mode === "Add" ? "Add" : "Edit"} Size</h5>
                    <button type="button" className="btn-close bg-label-secondary text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleReset} />
                </div>
                { /* Offcanvas Body */}
                <div className="offcanvas-body border-top">
                    <form className="pt-0" id="SizeForm" onSubmit={handleSubmit(handleFormSubmit)}>

                        { /* Hidden Field for Size Table Row ID */}
                        <input type="hidden" id="size-Id" {...register("hdnField_sizeId")} />

                        { /* Size Name */}
                        <InputField label="Name" id="size-name" type="text" placeholder="Enter Size Name" name="sizeName" register={register} errors={errors} required />

                        { /* Status */}
                        <StatusDropdown label="Select Size Status" id="size-status" name="sizeStatus" register={register} errors={errors} />
                       

                        { /* Submit and reset */}
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 data-submit">{mode === "Add" ? "Add" : "Update"}</button>
                            <button type="reset" className="btn bg-label-danger" data-bs-dismiss="offcanvas" onClick={handleReset}>Discard</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SizeModal